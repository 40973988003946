import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Site } from 'achelous/dist/data/models/Site';

import { SharedService } from '../../services/shared.service';

export interface SiteSearchOption {
  id: number;
  name: string;
  sitecode: string;
  characteristic?: string[];
}
@Component({
  selector: 'mhl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

  private domEl: HTMLElement;
  private inputEl: HTMLInputElement;

  public favouritable: boolean=true;
  public addToMap: boolean=true;
  public addToControls: boolean=true;
  public singleSite: boolean=false;
  public listAll: boolean=false;
  public showControls: boolean=true;
  public filterTypes: string[]=[];
  public defaultSite: string='';

  public stations: SiteSearchOption[];
  public search = '';
  public searchForm: FormGroup;
  public loading: boolean;

  constructor(
    private elRef: ElementRef,
    private _sharedService: SharedService,
    private _sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
    this.stations = [];
    let favouritable: string | boolean = this.domEl.getAttribute('favouritable');
    if (favouritable && !JSON.parse(favouritable)) this.favouritable = false;
    
    let singleSite: string = this.domEl.getAttribute('singleSite');
    if (singleSite && JSON.parse(singleSite)) this.singleSite = true;
    
    let listAll: string  = this.domEl.getAttribute('listAll');
    if (listAll && JSON.parse(listAll)) this.listAll = true;
    
    let showControls: string = this.domEl.getAttribute('showControls');
    if (showControls && JSON.parse(showControls) == false) this.showControls = false;
    
    let filterTypes: string = this.domEl.getAttribute('filterTypes');
    if (filterTypes && JSON.parse(filterTypes).length > 0) this.filterTypes = JSON.parse(filterTypes);
    
    let defaultSite: string = this.domEl.getAttribute('defaultSitecode');
    if (defaultSite) this.defaultSite = defaultSite;

    let addToMap: string | boolean = this.domEl.getAttribute('addToMap');
    if (addToMap && !JSON.parse(addToMap)) this.addToMap = false;

    let addToControls: string | boolean = this.domEl.getAttribute('addToControls');
    if (addToControls && !JSON.parse(addToControls)) this.addToControls = false;
  }

  ngOnInit(): void {
    this.loading = true
    this.searchForm = this.formBuilder.group({
      searchControl : '',
    });
    this.inputEl = this.domEl.getElementsByTagName('input')[0] as HTMLInputElement;
    this._sharedService.allSites$.subscribe(sites => {
        if (sites && sites.length > 0) {
          this.stations = sites.map(s => ({'id': s.id, 'name': s.name, 'sitecode': s.sitecode, 'characteristic': s.characteristic}));
	  if (this.listAll) { //change default ordering to alphabetical
	  	this.stations = this.stations.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
	  }
	  if (this.filterTypes.length > 0){
	  	this.stations = this.stations.filter(s => s.characteristic.some(r=> this.filterTypes.includes(r)));
	  }
	  if (this.defaultSite){
	  	const filteredSites = this.stations.filter(s => s.sitecode == this.defaultSite);
		if (filteredSites.length > 0){
		  this.searchForm.patchValue({searchControl: filteredSites[0].name});
		  this.updateDOM(filteredSites[0]);
		} else {
		  console.warn("No site found with sitecode " + this.defaultSite);
		}
	  }
          this.loading = false;
        }
    });
  }

  public autocompleListFormatter = (data: any) => {
    const html = `<div class="" style='width: 100%; height: 100%; margin: 0; padding: 0.25em 0.5em;'>${data.name} (${data.sitecode}) </div>`;
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  public async onSubmit() {
    const searchOption: SiteSearchOption = this.searchForm.controls['searchControl'].value;
    if (searchOption.sitecode) {
      this._sharedService.allSites$.pipe(take(1)).subscribe(allSites =>{
    
        if (allSites && allSites.length >0){
            const sites: Site = allSites.find(s => s.sitecode == searchOption.sitecode);
            if (this.addToMap && sites) {
              this._sharedService.updateSelectedSites(sites);
            }
            if (this.addToControls && this.singleSite && sites) { 
              this._sharedService.updateSelectedSitesForControls(sites,true);
            }
            if (this.addToControls && sites) {
              this._sharedService.updateSelectedSitesForControls(sites);
            }
            if (!this.addToMap && !this.addToControls) {
              console.warn(`Search complete but you explicitly set 'addToMap' and 'addToControls' attributes to false, hence the following result is not visible to on the UI`, sites);
            }
        }
      });
      // console.log('sites', sites);
    } else {
      console.error('Invalid search value!');
    }
    this.searchForm.reset();
  }
 
  public async addToFavourites(e) {
    e.preventDefault;
    const searchOption: SiteSearchOption = this.searchForm.controls['searchControl'].value;
    this._sharedService.addSitecodeToFavourites(searchOption.sitecode);
  }

  public updateDOM(e){
    this.domEl.setAttribute('sitecode',e.sitecode); 
  }
  public clearAll(){
      if (this.listAll){
        this.inputEl.value = '';
      }
  }
}
