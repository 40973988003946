import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { NotificationsService, Options } from 'angular2-notifications';

import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'mhl-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {

  public readonly options: Options = {
    position: ['bottom', 'right'],
    timeOut: 2500,
    maxStack: 3,
    preventLastDuplicates: true
  };

  constructor(
    private _sharedService: SharedService,
    private _notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this._sharedService.notifications$.subscribe(notification => {
      this._notificationsService.create(notification.title, notification.content, notification.type);
    });
  }

}
