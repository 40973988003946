import { Component, ViewEncapsulation, OnInit, ElementRef, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { FormGroup,  FormBuilder } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'mhl-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class RegionSelectorComponent implements OnInit {

  private domEl: HTMLElement;

  @Input() dropdownPosition: string = 'auto';
  public regionSelectForm: FormGroup;
  public regions = [];

  constructor(
    private elRef: ElementRef,
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private _helperService: HelperService
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
  }
  ngOnInit(): void {
    this.regionSelectForm = this.formBuilder.group({
      selectRegionControl: null
    });

    this._sharedService.collectionsList$.subscribe(collections => {
      this.regions = collections;    
    });
  }

  public changeRegion(event: any): void {
    if (event) {
      //console.log(event);
      this._sharedService.allSites$.pipe(take(1)).subscribe(sites =>{
        if (sites && sites.length > 0){
        this._sharedService.filterSitesByCollection(String(event.id),sites, true);
        }
      });
    } else {
      this._sharedService.updateSelectedSites([], false);
    }
  }

}
