import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { NgProgress } from 'ngx-progressbar';
import { CookieService } from 'ngx-cookie-service';
import { HelperService } from './helper.service';
import * as Mhl from 'achelous/dist/mhl';
import { AuthToken } from 'achelous/dist/data/models/AuthToken';
import { Site, LatestReadings, SensorSummary } from 'achelous/dist/data/models/Site.d';
import { Reading } from 'achelous/dist/data/models/Reading.d';
import { CollectionValue } from 'achelous/dist/data/models/raw/RawData';
import { any } from 'underscore';

export interface DownloadLinkI {
  success: boolean;
  response: string;
}

export interface DateRange {
  fromDate: Date;
  toDate: Date;
}

export interface Favourites {
  sites: Site[];
  loadPlots: boolean;
  run: boolean;
}

@Injectable()
export class SharedService {
  private UPDATE_TIME: number = 0.25 //hours between force refresh of metadata
  public host = 'https://api.manly.hydraulics.works/api';
  public verbose: boolean;
  public authToken: AuthToken;
  public username = 'publicwww';
  public token: string = '';
  private COOKIE_NAME: string = 'MHLJWT';
  public chartInterval: string;
  public ahd: boolean;
  public selectParams;
  public isChart: boolean = false;
  public isTable: boolean = false;
  public isControls: boolean = false;
  public isMap: boolean = false;
  public isFavs: boolean = false;
  public defaultDateFormat: string = 'yyyy-MM-dd';
  public DEFAULT_COLLECTIONS: string[] = ["200","201","202","203","204","205","206","207","208","209","210","211","212","213","214","215","216","217","218","219","220"];

  private authenticatedSource = new BehaviorSubject<boolean>(false); // TODO: interface?
  public authenticated$ = this.authenticatedSource.asObservable();

  private notificationsSource = new BehaviorSubject<any>([]); // TODO: interface?
  public notifications$ = this.notificationsSource.asObservable();

  private allSitesSource = new BehaviorSubject<Site[]>([]); 
  public allSites$ = this.allSitesSource.asObservable();

  private selectedStationTypesSource = new BehaviorSubject<string[]>([]);
  public selectedStationTypes$ = this.selectedStationTypesSource.asObservable();

  private selectedSitesSource = new BehaviorSubject<Site[]>([]);
  public selectedSites$ = this.selectedSitesSource.asObservable();

  private favouritesSource = new BehaviorSubject<Favourites>({sites: [], loadPlots: true, run: false});
  public favourites$ = this.favouritesSource.asObservable();

  private sitesLoadingSource = new BehaviorSubject<boolean>(false);
  public sitesLoading$ = this.sitesLoadingSource.asObservable();

  private collectionsLoadingSource = new BehaviorSubject<boolean>(false);
  public collectionsLoading$ = this.collectionsLoadingSource.asObservable();

  private selectedSitesForControlsSource = new BehaviorSubject<Site[]>([]);
  public selectedSitesForControls$ = this.selectedSitesForControlsSource.asObservable();

  private selectedSitesForChartsSource = new BehaviorSubject<Site[]>([]);
  public selectedSitesForCharts$ = this.selectedSitesForChartsSource.asObservable();

  private datesSource = new BehaviorSubject<DateRange>({ fromDate: null, toDate: null });
  public dates$ = this.datesSource.asObservable();

  private sensorsForChartsSource = new BehaviorSubject<string[]>([]);
  public sensorsForCharts$ = this.sensorsForChartsSource.asObservable();

  private readingsSource = new BehaviorSubject<Reading>({meta:{},columns:[],readings:[]});
  public readings$ = this.readingsSource.asObservable();

  private collectionsListSource = new BehaviorSubject<CollectionValue[]>([]);
  public collectionsList$ = this.collectionsListSource.asObservable();

  private availableCollectionsSource = new BehaviorSubject<string[]>([]);
  public availableCollections$ = this.availableCollectionsSource.asObservable();

  private readingSensorsSource = new BehaviorSubject<string[]>([]);
  public readingSensors$ = this.readingSensorsSource.asObservable();

  constructor(
    private datePipe: DatePipe,
    private localStorage: LocalStorage,
    private _helperService: HelperService,
    public ngProgress: NgProgress,
    private _cookieService: CookieService
  ) {
    this.checkAuthState();
    this.checkCollectionsList();
    //check allSites once collections have loaded and if not overridden
    combineLatest(this.sitesLoading$,this.collectionsLoading$)
      .pipe(takeWhile(([sl,cl]) => cl, true)).subscribe(async ([sl, cl]) =>{
        if (!cl && !sl){
            this.allSitesUpdateCheck();
        }
    });
    this.checkfavSites();
    const verbose = window.localStorage.getItem('verbose');
    verbose ? this.verbose = _helperService.parseAttribute('boolean', verbose, false, 'verbose (in local storage)') as boolean : this.verbose = false;
  }
  public async checkAuthState(): Promise<void> {
    const authState = await Mhl.Auth.authState();
    //console.log(authState ? authState : 'not authorised'); // logged in console from achelous .authState() call
    if (authState) {
      this.authToken = authState;
      this.username = authState.username;
      this.token = authState.token;
    }
    this.checkAuthCookie('MHLJWT');
  }
  private async checkfavSites(): Promise<void> {
    let newFavs = this.favouritesSource.value;
    this.localStorage.getItem('favSites').pipe(take(1)).subscribe((favs: any) => {
        if (favs && favs.sites.length > 0 && !newFavs.run){
          newFavs.sites = favs.sites;
          newFavs.run = true;
          newFavs.loadPlots = favs.override;
        } else {
          newFavs.run = true;
        }
        this.favouritesSource.next(newFavs);
    });
    this.favourites$.subscribe(favs =>{
      if (favs && favs.run){
        this.localStorage.setItem('favSites',{sites: favs.sites, override: favs.loadPlots}).subscribe();
      }
    });
  }
  public async isAuthenticated(): Promise<AuthToken> {
    const state = await Mhl.Auth.authState();
    this.authenticatedSource.next(state !== undefined ? true : false);
    return state;
  }
  private async checkAuthCookie(cName: string): Promise<void> {
    if (!cName) cName = this.COOKIE_NAME;
    let cookiePresent: boolean = this._cookieService.check(cName);
    if (cookiePresent){
      const jwt: string = this._cookieService.get(cName);
      const [success, ret] = Mhl.Auth.compareJWTAuth(jwt, this.username);
      if (success && ret){
        this.pushNotification('success', 'Authorised', 'Logged in successfully');
        this.authToken = ret;
        this.username = ret.username;
        this.token = ret.token;
        this.authenticatedSource.next(true);
      } else if (!success){
        console.warn('JWT login unseccessful with return', ret);
      } else if (success){
        this.authenticatedSource.next(true); //already logged in (JWT matches authtoken)
      }
    }
    if (!this.authToken){ //catch for no or failed login info
        const authState = await Mhl.Auth.login(this.username,''); //should not need password for default login
        this.authToken = authState;
        this.username = authState.username;
        this.token = authState.token;
        this.authenticatedSource.next(true);
    } else {
        this.authenticatedSource.next(true);
    }
  }

  public pushNotification(type: string, title: string, content: string): void {
    const notification = { type, title, content };
    this.notificationsSource.next(notification);
  }

  
  public async allSitesUpdateCheck(): Promise<void> {
    this.localStorage.getItem('allSites').pipe(take(1)).subscribe((sites) => {
      if (!sites || sites.sites.length == 0){
        this.updateAllSites();
        return;
      } 
      const validDate = new Date(sites['validUntil']).getTime();
      const currentDate = new Date().getTime();
      if (currentDate > validDate){//should probably do something with caching instead to detect when changes occur and update accordingly
        this.updateAllSites();
        return;
      }
      this.allSitesSource.next(sites.sites);
    });
  }

  public async updateAllSites(): Promise<void>{
    this.ngProgress.start();
    this.sitesLoadingSource.next(true);
    if (this.verbose) { console.time('getAllSites'); }
    this.pushNotification('info', 'Loading...', 'Updating site metadata');
    //const colls = this.collectionsListSource.value;
    this.collectionsList$.pipe(takeWhile(c => !(c && c.length > 0),true)).subscribe(async colls => {
    //DEBUG console.log(colls);
    let collections = ["200"];
    if (colls && colls.length >0){
    const useCollections = colls.map(c => c.id);
    const pubmatch = '(2[01][0-9]|220)'; //filter out oeh collections
        for (let col of useCollections) {
            if (!col.match(pubmatch)){
                collections.push(col);
            }
        }  
    const sites: Site[] = await Mhl.Sites.getSitesByCollection(collections);
    let validDate: Date = new Date();
    validDate = new Date(validDate.getTime() + (this.UPDATE_TIME * 3600 * 1000));
    const allSites = {validUntil: validDate, sites: sites};
    this.localStorage.setItem('allSites',allSites).subscribe();
    this.allSitesSource.next(sites); 
    this.ngProgress.done();
    this.sitesLoadingSource.next(false);
    if (this.verbose) { console.timeEnd('getAllSites'); }
    this.pushNotification('success', 'Done!', 'All sites loaded');
    }
    });
  }

  public async checkCollectionsList(): Promise<void> {
    this.collectionsLoadingSource.next(true);
    combineLatest(this.authenticated$,this.localStorage.getItem('collections'))
      .pipe(takeWhile(([a,c]) => !a, true)).subscribe(async ([auth, colls]) =>{
        if (auth && colls){
          const availableCols = this.authToken.collections;
          const validDate = new Date(colls['validUntil']).getTime();
          const currentDate = new Date().getTime();
          //console.log('collection dates',currentDate,validDate);
          let colIds = colls['availableCollections'];
          
          if (availableCols.every(c => colIds.includes(c)) && availableCols.length == colIds.length && currentDate < validDate) { //auth collections same as stored collections
            const defaults = colls['defaultCollections'].filter(c => availableCols.includes(c.id)); //remove collections which are no longer valid
            this.collectionsListSource.next(defaults);
            this.availableCollectionsSource.next(availableCols);
            this.collectionsLoadingSource.next(false);
            return;
          }
        }
        if (auth){
          if (this.verbose) { console.time('getCollectionsList'); }  
          const availableCols = this.authToken.collections;
          this.availableCollectionsSource.next(availableCols);
          this.ngProgress.start();
          this.pushNotification('info', 'Loading...', 'Fetching collections list');
          let colToken = {'availableCollections': availableCols};
          const usercollections = await Mhl.Sites.getCollectionList(this.username);
          colToken['defaultCollections'] = usercollections.collections.filter(c => availableCols.includes(c.id));
          let validDate: Date = new Date();
          validDate = new Date(validDate.getTime() + (this.UPDATE_TIME * 3600 * 1000));
          colToken['validUntil'] = validDate;
          // console.log('collections', collections);
          this.localStorage.setItem('collections', colToken).subscribe();
          this.collectionsListSource.next(colToken['defaultCollections']);
          this.ngProgress.done();
          if (this.verbose) { console.timeEnd('getCollectionsList'); }

          //override default update check and update with new collections
          this.sitesLoadingSource.next(true);
          this.collectionsLoadingSource.next(false);
          this.updateAllSites();
        }
      });   
  }
  public async addNewCollection(collection: string[], filter: boolean=true): Promise<void> {
      combineLatest(this.availableCollections$,this.localStorage.getItem('collections'))
        .pipe(takeWhile(([ac,c]) => ac && ac.length == 0,true)).subscribe(async ([availableCollections, colls]) => { 
            if (availableCollections && availableCollections.length > 0){
                collection.forEach((f,i) => {
                    if (!availableCollections.includes(f)){
                      console.warn(`Collection ${f} is unavailable to this user`);
                      collection.splice(i,1);
                    }
                });
                let newCollections = [];
                for (const c of collection){
                    const colData = await Mhl.Sites.getCollectionData(c);
                    if (c.length > 0){
                    newCollections = [...newCollections, colData[0]];
                    }
                }
                if (colls && colls['defaultCollections'].length > 0){
                    colls['defaultCollections'] = this._helperService.removeDuplicates([...newCollections,...colls['defaultCollections']]);
                    this.collectionsListSource.next(colls['defaultCollections']);
                    this.localStorage.setItem('collections', colls).subscribe();
                }
                await this.updateAllSites(); //will not actually wait so need to do sitesLoading subscription
                this.sitesLoading$.pipe(takeWhile(s => s == true, true)).subscribe(loading => {
                if (filter && !loading){
                    //DEBUG console.log('newsites',this.allSitesSource.value.length);
                    this.filterSitesByCollection(collection,[...this.allSitesSource.value],true);
                }
                });
            }
        });
  }

  public getCollectionsFromSites(sites: Site[]): string[] {
    let colls: string[] = [].concat(...sites.map(s =>s.collections)); //collect and flatten collection arrays
    return this._helperService.removeDuplicates(colls);
  }
  public getChartTypes(): string[] {
    const meta = this.readingsSource.value.meta;
    let stationTypes: string[] = Object.values(meta).map(s=>s.parameter);
    stationTypes = this._helperService.removeDuplicates(stationTypes);
    // console.log(`stationTypes`, stationTypes);
    return stationTypes;
  }

  /** @description Adds station types to the array of selected ones which can then be observed using selectedStationTypes$
   * @param {string | string[] | JSON} stationType - Station types aka `characteristic` of a station
   * @version 2.0
   */
  public updateSelectedStationTypes(stationType: string | string[] | JSON): void {
    // console.log(stationType);
    const currentSelections = this.selectedStationTypesSource.value;
    //console.log('current types',currentSelections);
    let newSelections: string[] = [];

    if (Array.isArray(stationType)) {
      stationType.forEach(el => {
        if (currentSelections.includes(el)) {
          newSelections = currentSelections.filter(item => item !== el);
        } else {
          newSelections = [...currentSelections, ...newSelections, el];
        }
      });
    } else {
      if (currentSelections.includes(stationType as string)) {
        newSelections = currentSelections.filter(item => item !== stationType);
      } else {
        newSelections = currentSelections.concat(stationType as string);
      }
    }

    newSelections = this._helperService.removeDuplicates(newSelections);
    //console.log('selectedtypes',newSelections);
    this.selectedStationTypesSource.next(newSelections);
  }

  /** @description Returns array of sites from supplied station type as string or array of station types (station type aka characteristics)
   * @param {string | string[]} stationType aka `characteristics` of a station/site
   * @version 3.0
   */
  public async filterSitesByType(stationType: string | string[], sites?: Site[], override?: boolean): Promise<void> {
    //console.log('stationTypes',stationType);
    this.allSitesSource.pipe(takeWhile(val => val.length == 0,true)).subscribe(s => {
        //console.log('allsites',s);
        if (!sites || sites.length == 0){
            sites = s;
        }
        if (sites.length > 0){
            let selectedSites: Site[] = [];
            if (!override){
                selectedSites = [...this.selectedSitesSource.value];
            } else if (this.selectedSitesForControlsSource.value.length > 0){
                selectedSites = [...this.selectedSitesForControlsSource.value]; //need deconstructor to prevent reference to selectedSitesForControlsSource
            }
            if (!Array.isArray(stationType)) {
            if (stationType == '' || stationType == 'all'){
                selectedSites = sites;
            } else {
                stationType = [stationType];
            }
            }
            if (stationType.length > 0) {
            for (const site of sites) {
                if (site.characteristic.some(s=> stationType.includes(s))){
                selectedSites.push(site);
                }
            }
            }
            //console.log('filterSites',selectedSites);
            selectedSites = this._helperService.removeDuplicates(selectedSites);
            this.selectedSitesSource.next(selectedSites);
        }
    });
    }

  public async filterSitesByCollection(collections: string | string[], sites: Site[], override?: boolean): Promise<Site[]> {
    let selectedSites: Site[] = [];
    if (!Array.isArray(collections)) {
      if (collections == ''){
        selectedSites = sites;
      } else {
        collections = collections.split(",");
      }
    }
    if (collections.length > 0) {
      for (const site of sites) {
        if ((collections as string[]).some(s=> site.collections.includes(s))){
          selectedSites.push(site);
        }
      }
    }
    if (override) { await this.filterSitesByType([]); }
    if (override) { await this.updateSelectedSites(selectedSites, true); }
    return selectedSites;
  }

  public isfavourited(sitecode: string): boolean {
    const favouriteSitecodes = this.favouritesSource.value.sites.map(s => s.sitecode);
    return favouriteSitecodes.includes(sitecode);
  }
  public async updateFavourites(favs: Favourites): Promise<void> {
    const newFavs = this.favouritesSource.value;
    if (favs && !this._helperService.arrayMatch(favs.sites.map(s=>s.sitecode),newFavs.sites.map(s=>s.sitecode))){
      newFavs.sites = [...favs.sites];
    }
     if (favs.loadPlots !== newFavs.loadPlots){
      newFavs.loadPlots = !newFavs.loadPlots;
    }
    this.favouritesSource.next(newFavs);
  }
  
    public addSitecodeToFavourites(sitecode: string): void {
    let favourites: Favourites = this.favouritesSource.value;
    const exists = favourites.sites.findIndex(site => site.sitecode === sitecode);
    //console.log(exists);
    if (exists >= 0){
      favourites.sites.splice(exists,1);
    } else {
      const favSite = this.allSitesSource.value.find(site => site.sitecode === sitecode);
      //console.log(favSite);
      if (favSite){
        favourites.sites.push(favSite);
        //console.log(favourites.sites);
      } else {
        console.warn("sitecode: ",sitecode, " not found in allSites. Might need to reload.");
      }
    }
    this.favouritesSource.next(favourites);
  }

  public clearFavourites(loadPlots: boolean): void {
    this.favouritesSource.next({sites: [], loadPlots: loadPlots, run: true});
  }
  public async getSitesFromSitecodes(sitecodes: string | string[]): Promise<Site[]> {
    // console.log('sitecodes', sitecodes);
    if (this.verbose) { console.time('getSitesFromSiteCodes'); }
    if (!Array.isArray(sitecodes)) {
      sitecodes = sitecodes.split(',');
    }
    const sites = await Mhl.Sites.getSitesBySitecodes(sitecodes);
    // console.log('sites', sites);
    if (this.verbose) { console.timeEnd('getSitesFromSiteCodes'); }
    return sites;
  }

  public async updateSelectedSites(site: Site | Site[], override?: boolean): Promise<void> {
    //console.log('override sites',override, site);
    const currentSelections = this.selectedSitesSource.value;
    // console.log('current selections', currentSelections);
    let newSelections: Site[];
    if (override) {
      newSelections = [];
    } else {
      newSelections = [].concat(currentSelections);
    }
    // console.log('new selections before', currentSelections);
    if (Array.isArray(site)) {
      site.forEach(el => {
        if (currentSelections.find(item => item.sitecode === el.sitecode)) {
          // console.log('same', el);
          newSelections = newSelections.filter(item => item.sitecode !== el.sitecode);
        } else {
          newSelections.push(el);
          // console.log('new', el);
        }
        // console.log('new selections during', newSelections);
      });
    } else {
      if (currentSelections.includes(site)) {
        newSelections = currentSelections.filter(item => item.sitecode !== site.sitecode);
      } else {
        newSelections.push(site);
      }
    }
    // console.log('new selections after', newSelections);
    this.selectedSitesSource.next(newSelections);
  }

  public updateSelectedSitesForControls(site: Site | Site[], override: boolean = false): void {
    const currentSelections = [...this.selectedSitesForControlsSource.value];
    let newSelections: Site[] = [].concat(currentSelections);   
    if (Array.isArray(site)) {
      site.forEach(el => {
        if (currentSelections.map(s => s.sitecode).includes(el.sitecode)) {
          newSelections = newSelections.filter(item => item.sitecode !== el.sitecode);
        } else {
          newSelections.push(el);
        }
      });
    } else {
      if (currentSelections.map(s => s.sitecode).includes(site.sitecode)) {
        //console.log('removing from controls:',currentSelections);
        newSelections = currentSelections.filter(item => item.sitecode !== site.sitecode);
      } else {
        newSelections.push(site);
      }
    }
    if (override){
        newSelections = [].concat(site);
    }
    if (!this.favouritesSource.value.run && this.isFavs){
        this.favouritesSource.pipe(takeWhile(val => !val.run,true)).subscribe(f => {
            if (f.run){
            if (f.loadPlots && f.sites.length > 0){
                    newSelections.push(...f.sites);
                    newSelections = this._helperService.removeDuplicates(newSelections);
                }
                this.selectedSitesForControlsSource.next(newSelections);
            }
        });
    } else {
        this.selectedSitesForControlsSource.next(newSelections);
    }
  }
  public removeSelectedSitesFromControls(sites: Site[]): void {
    const currentSelections = this.selectedSitesForControlsSource.value;
    const filterCodes = sites.map(s => s.sitecode);
    let newSelections: Site[] = [].concat(currentSelections);
    newSelections = newSelections.filter(s => !filterCodes.includes(s.sitecode));
    this.selectedSitesForControlsSource.next(newSelections);
  }
  public async updateDates(fromDate: any, toDate: any): Promise<void> {  
    const curDates = this.datesSource.value;
    //DEBUG console.log(curDates,fromDate,toDate);
    if (typeof(fromDate) == 'string'){
      fromDate = new Date(fromDate);
    }
    if (typeof(toDate) == 'string'){
      toDate = new Date(toDate);
    }
    if (fromDate == curDates.fromDate && toDate == curDates.toDate){
      console.log('Same dates selected. Not updating.');
      return;
    }
    if (toDate === null) {
      if (!fromDate || fromDate === null){
        return;
      } else {
        this.datesSource.next({ fromDate: fromDate, toDate: null });
        return;
      }
    }
    if (toDate > fromDate){ 
      this.datesSource.next({ fromDate: fromDate, toDate: toDate });
      this.getReadingsFromSensors();
    } else {
      console.error(`Beginning date cannot be greater than ending date`);
    }
  }

  public updateSelectedSitesForCharts(site: Site | Site[]): void {
    this.selectedSitesForChartsSource.next(site as Site[]);
  }
  public getSitesFromSensors(sensors: string | string[], sites?: Site[]): Site[] {
    if (!sites || sites.length == 0){
      sites = this.allSitesSource.value; 
    }
    let foundSites: Site[] = [];
    if (!Array.isArray(sensors)){
      sensors = sensors.split(',');
    }
    for (const s of sensors){
      const found = sites.find(site => site.sensor_ids.includes(s));
      if (found){
        foundSites.push(found);
      } else {
        console.warn("No site found for sensor_id ", s, " consider reloading allSites.");
      }
    }
  return foundSites;
  }
  public updateSensorsForCharts(sensor: string | string[]) {
    const sensorsForCharts = this.sensorsForChartsSource.value;
    let newSensorsForCharts: string[] = [].concat(sensorsForCharts);
    if (Array.isArray(sensor)) {
      sensor.forEach(s => {
        if (!newSensorsForCharts.includes(s)) {
          newSensorsForCharts.push(s);
        } else {
          newSensorsForCharts = newSensorsForCharts.filter(el => el !== s);
        }
      });
    } else {
      if (!newSensorsForCharts.includes(sensor)) {
        newSensorsForCharts.push(sensor);
      } else {
        newSensorsForCharts = newSensorsForCharts.filter(el => el !== sensor);
      }
    }
    newSensorsForCharts = this._helperService.removeDuplicates(newSensorsForCharts);
    this.sensorsForChartsSource.next(newSensorsForCharts);
  }

  public async getLatestReadingsFromSite(site: Site| Site[]): Promise<LatestReadings> {
    let sitecode: string = '';
    if (Array.isArray(site)){
        sitecode = site.map(s => s.sitecode).join(',');
    } else {
        sitecode = site.sitecode;
        site = [site];
    }
    const latest_vals = await Mhl.Sites.getLatestSiteReadings(sitecode);
    for (const s of site){
        s.latest_values = this._helperService.subset(latest_vals,s.sensor_ids);
        const siteDex = this.allSitesSource.value.findIndex(x => x.sitecode == s.sitecode);
        if (siteDex >=0){
            this.allSitesSource.value[siteDex].latest_values = s.latest_values;
        }
    }    
    return latest_vals;
  }

  public async getSiteSummary(sitecode: string, datetrunc: string, sumtype: string | string[], startDate?: any, endDate?: any): Promise<SensorSummary> {
    this.ngProgress.start();
    this.pushNotification('info', 'Loading...', 'Fetching summary statistics');
    if (this.verbose) { console.time('getSiteSummary'); }
    const _dates = this.datesSource.value;
    let fromDate: string = '';
    let toDate: string = '';
    let siteSummary: SensorSummary;
    if (startDate){
      fromDate = (typeof startDate !== 'string') ? this.datePipe.transform(startDate,this.defaultDateFormat) : startDate;
    } else if (_dates.fromDate){
      fromDate = this.datePipe.transform(_dates.fromDate,this.defaultDateFormat);
    }

    if (endDate){
      toDate = (typeof endDate !== 'string') ? this.datePipe.transform(endDate,this.defaultDateFormat) : endDate;
    } else if (_dates.toDate){
      toDate = this.datePipe.transform(_dates.toDate,this.defaultDateFormat);
    }
    siteSummary = await Mhl.Sites.getSiteSummary(sitecode,datetrunc,sumtype,fromDate,toDate);
    if (this.verbose) { console.timeEnd('getSiteSummary'); }
    this.ngProgress.done();
    return siteSummary;
  }
  public async getReadingsFromSensors(override: boolean = true, sensors?: string[], dates?: any, interval?: string, ahd?: any): Promise<Reading> {
    const _dates = this.datesSource.value;
      //DEBUG console.log("_dates:",_dates);
      //DEBUG console.log("dates:",dates);
    if ((!sensors || sensors.length == 0) && override){
        sensors = this.sensorsForChartsSource.value;
    }
    if (!sensors || sensors.length == 0){
          const readings =  {meta: {}, columns: {}, readings:[]};
          return readings;
    }
    if (!interval){
      interval = this.chartInterval;
    }
    if (!ahd && !this.ahd){
      ahd = 'false';
    } else if (ahd) {
        ahd = String(ahd);
    } else if (this.ahd){
        ahd = String(this.ahd);
    }
    this.ngProgress.start();
    this.pushNotification('info', 'Loading...', 'Fetching site readings');
    if (this.verbose) { console.time('getReadingsFromSensors'); }

    let allReadings: Reading;
    let fromDateString = '';
    let toDateString = '';
    if (dates) {
      if (dates.fromDate){
          fromDateString = this.datePipe.transform(dates.fromDate,this.defaultDateFormat);  
      } else {
          fromDateString = '';
      }
      if (dates.toDate){
        toDateString = this.datePipe.transform(dates.toDate,this.defaultDateFormat); 
      } else {
        toDateString = '';   
      }     
      allReadings = await Mhl.Readings.getReadings(sensors,fromDateString, toDateString, interval, ahd);
    } else if (_dates) {
      if (_dates.fromDate){ 
          fromDateString = this.datePipe.transform(_dates.fromDate,this.defaultDateFormat);;  
          } else {
              fromDateString = '';
          }
      if (_dates.toDate){
          toDateString = this.datePipe.transform(_dates.toDate,this.defaultDateFormat);;  
      } else {
          toDateString = '';   
      }     
      if (fromDateString !== '' || toDateString !== '') {
          allReadings = await Mhl.Readings.getReadings(sensors,fromDateString, toDateString,interval,ahd);
      } else {
          allReadings = await Mhl.Readings.getReadings(sensors,'','',interval,ahd);
      }
    }
    this.updateReadingsForAllSites(allReadings);
    if (this.verbose) { console.timeEnd('getReadingsFromSensors'); }
    this.ngProgress.done();
    setTimeout(() => {
    this.pushNotification('success', 'Done', 'Data collected')
    }, 1);
    if (override){this.readingsSource.next(allReadings)}
    if (override && this.sensorsForChartsSource.value.length == 0){
      this.sensorsForChartsSource.next(sensors);
    }
    return allReadings;
  }
  public clearReadings(): void {
    const readings =  {meta: {}, columns: {}, readings:[]};
    this.readingsSource.next(readings);  
  }
  public async updateReadingsFromSensors(sensors: string[],readings?: Reading, override?:boolean): Promise<Reading> {
    if (!override){
      override = true;
    }
    if (!readings || readings.readings.length == 0){
      readings = this.readingsSource.value;
      override = true; 
    }
    const readingSensors = Object.keys(readings.meta);
    const badSensors = readingSensors.filter(s=> !sensors.includes(s));
    if (badSensors.length > 0){
      for (const s of badSensors){
        delete readings.meta[s]
        delete readings.stats[s]
        delete readings.columns[s]
        readings.readings.forEach(reading =>{
          delete reading.values[s]
        });
      }
    }
    const newSensors = sensors.filter(s => !badSensors.includes(s) && !readingSensors.includes(s));
    if (newSensors.length > 0){
      const existReadings = this.allSitesSource.value.filter(s => s.readings as Reading && s.readings.readings.length > 0);
      let existSensors: any = existReadings.map(r => Object.keys(r.readings.meta));
      existSensors = existSensors.length > 0 ? [].concat(existSensors)[0] : existSensors;
      //console.log(sensors,existSensors);
      let getSensors = [];
      let newReadings: Reading = {meta: {}, columns: {}, stats:{}, readings: []};
      for (const s of newSensors){
        if (existSensors.includes(s)){
          const read = existReadings.filter(r => Object.keys(r.readings.meta).includes(s))[0];
          //console.log('sensor',s);
          //console.log('readings',read);
          newReadings.meta[s] = read.readings.meta[s];
          newReadings.columns[s] = read.readings.columns[s];
          newReadings.stats[s] = read.readings.stats[s];
          newReadings.readings.push(...read.readings.readings);
        } else {
          getSensors.push(s);
        }
      }
      if (getSensors.length >0){
        const getReadings = await this.getReadingsFromSensors(false,getSensors,undefined,this.chartInterval,String(this.ahd));
        //console.log('getReadings',getReadings);
        newReadings.meta = {...newReadings.meta, ...getReadings.meta};
        newReadings.columns = {...newReadings.columns, ...getReadings.columns};
        newReadings.stats = {...newReadings.stats, ...getReadings.stats};
        newReadings.readings.push(...getReadings.readings);
      }
      //console.log('newReadings',newReadings);
      readings.meta = {...newReadings.meta, ...readings.meta};
      readings.columns = {...newReadings.columns, ...readings.columns};
      readings.stats = {...newReadings.stats, ...readings.stats};
      const times = readings.readings.map(r => r.rawTimestamp);
      const foundDex = newReadings.readings.map(s => times.findIndex(t => t == s.rawTimestamp));
      for (const i in foundDex){
        if (foundDex[i] >= 0) {
          readings.readings[foundDex[i]].values = {...newReadings.readings[i].values,...readings.readings[foundDex[i]].values}
        } else {
          readings.readings.push(newReadings.readings[i]);
        }
      }
      readings.readings.sort((a,b) =>a.timestamp.getTime() - b.timestamp.getTime());
    }
    if (override){
      this.readingsSource.next(readings);
    }
    return readings
  }
  public async updateReadingsForAllSites(readings: Reading): Promise<void>{
    let allsites = this.allSitesSource.value;
    const sensors = Object.keys(readings.meta);
    if (allsites.length > 0){
      for (const s of sensors){
        const siteDex = allsites.findIndex(r => r.sitecode == readings.meta[s].sitecode);
        if (siteDex >=0){
          allsites[siteDex].readings.meta[s] = readings.meta[s];
          allsites[siteDex].readings.columns[s] = readings.columns[s];
          allsites[siteDex].readings.stats[s] = readings.stats[s];
          const existTimes = allsites[siteDex].readings.readings.map(r => r.rawTimestamp);
          const foundDex = readings.readings.map(s => existTimes.findIndex(t => t == s.rawTimestamp));
          for (const i in foundDex){
            if (foundDex[i] >= 0) {
                allsites[siteDex].readings.readings[foundDex[i]].values = {...readings.readings[i].values,...allsites[siteDex].readings.readings[foundDex[i]].values};
              } else {
                allsites[siteDex].readings.readings.push(readings.readings[i]);
              }
            }
            allsites[siteDex].readings.readings.sort((a,b) =>a.timestamp.getTime() - b.timestamp.getTime());
          }
        }
        this.allSitesSource.next(allsites);
        let updateSensors = this.readingSensorsSource.value
        updateSensors.push(...sensors);
        this.readingSensorsSource.next(updateSensors);
      }
  }
  public async generateDownloadLink(sensorIds: string[], fromDate?: string | Date, toDate?: string| Date, interval?: string): Promise<string> {
    if (!fromDate) fromDate = this.datesSource.value.fromDate;
    if (!toDate) toDate = this.datesSource.value.toDate;
    if (fromDate instanceof Date) fromDate = this.datePipe.transform(fromDate,this.defaultDateFormat);
    if (toDate instanceof Date) toDate = this.datePipe.transform(toDate,this.defaultDateFormat);
    if (!interval) interval = '';
    return await Mhl.Readings.generateDownloadLink(sensorIds, fromDate,toDate,interval);
  }

  public async download(url: string, filename?: string, extension?: string): Promise<boolean> {
    let success: boolean;

    if (this._helperService.isUrl(url)) {
      const res = await fetch(url);
      if (res.ok) {
        const blob = await res.blob();

        const href = URL.createObjectURL(
          new Blob(['\ufeff', blob], {
            type: 'application/octet-stream',
          })
        );

        const linkEl = document.createElement('a');
        linkEl.href = href;
        const _extension = extension ? extension : 'csv';
        linkEl.download = filename ? `${filename}.${_extension}` : `MHL data.${_extension}`;

        document.body.appendChild(linkEl);
        linkEl.click();
        document.body.removeChild(linkEl);

        this.pushNotification('success', 'Downloaded!', 'File successfully downloaded');

        success = true;
      } else {
        success = false;
        await this.pushNotification('error', 'Download error!', `${await res.text()}`);
      }
    } else {
      success = true; // because doesn't signify that permissions were insufficient
      console.error(url, 'is not URL');
      this.pushNotification('error', 'Download error!', `${url} is not URL`);
    }

    return success;
  }

}
