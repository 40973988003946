import { Injectable } from '@angular/core';

export interface ParsedAttributeResultI {
  success: boolean;
  parsedValue?: boolean | JSON;
  error?: string;
}

const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

@Injectable()
export class HelperService {

  constructor() { }

  public parseAttribute(type: string, value: string, defaultValue: any, attr?: string): JSON | boolean | string | string[] | number | number[] {
    const _type = type.toLowerCase();
    let response: ParsedAttributeResultI;
    switch (_type) {
      case 'json':
        response = this.parseJSON(value);
        break;
      case 'boolean':
        response = this.parseBoolean(value.toLowerCase());
        break;
      default:
        response = {
          success: false,
          error: 'Provided type for parsing is not supported. Use either `boolean` or `json`'
        };
    }

    if (response.success) {
      return response.parsedValue;
    } else {
      console.error(`An error occured in attempt to parse`, attr ? `attribute '${attr}'` : ``, `value to ${type}`, response.error ? `\n${value}\n${response.error}` : value);
      return defaultValue;
    }
  }

  private parseJSON(string: string): ParsedAttributeResultI {
    let result: ParsedAttributeResultI;
    try {
      const parsed = JSON.parse(string,this.reviveFunctions); //pass reviver to parse functions correctly
      result = {
        success: true,
        parsedValue: parsed
      };
      return result;
    } catch (err) {
      result = {
        success: false,
        error: err
      };
      return result;
    }
  }

  private parseBoolean(string: string): ParsedAttributeResultI {
    let result: ParsedAttributeResultI;
    let response: boolean;
    switch (false) {
      case string.toLowerCase() !== 'true':
        response = true;
        break;
      case string.toLowerCase() !== 'false':
        response = false;
        break;
    }

    if (typeof response === 'boolean') {
      result = {
        success: true,
        parsedValue: response
      };
    } else {
      result = {
        success: false
      };
    }

    return result;
  }

  public removeDuplicates(array: any[]): any[] {
    return Array.from(new Set(array));
  }

  public toNumber(string: string): number {
    return parseFloat(string);
  }

  public groupBy(xs, key): object {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  public diffArray(arr1, arr2) {
  return arr1
    .concat(arr2)
    .filter(item => !arr1.includes(item) || !arr2.includes(item));
  }
  public isValidDate(date: any): boolean {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      if (isNaN(date.getTime())) {
        // date is not valid
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  public serialize(obj: object): string {
    return Object.keys(obj).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`).join('&');
  }
  /**
   * Coverts strings to camel case and removes all brackets (e.g. one two -> oneTwo) [necessary for key naming for datatables]
   * @description code taken from ngx-datatables to ensure that camelizing procedure is the same
   * @author https://github.com/swimlane/ngx-datatable/blob/master/src/utils/camel-case.ts
   */
  public camelize(str: string) {
    // Replace special characters with a space
    str = str.replace(/[^a-zA-Z0-9 ]/g, ' ');
    // put a space before an uppercase letter
    str = str.replace(/([a-z](?=[A-Z]))/g, '$1 ');

    // Lower case first character and some other stuff
    str = str.replace(/([^a-zA-Z0-9 ])|^[0-9]+/g, '').trim().toLowerCase();

    // uppercase characters preceded by a space or number
    str = str.replace(/([ 0-9]+)([a-zA-Z])/g, function (a, b, c) {
      return b.trim() + c.toUpperCase();
    });

    str = str.replace(' ', '');

    return str;
  }
  public isEqual = function (value, other) {

	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {
		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!this.isEqual(item1, item2)) return false;
		}
		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}
		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};
public arrayMatch(arr1, arr2): boolean {
	if (arr1.length !== arr2.length) return false;
	for (var i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) return false;
	}
	return true;
};
  public titleCase(str: string): string {
    let outArr = [];
    for (const s of str.trim().split(' ')){
      outArr.push(s.charAt(0).toUpperCase() + s.slice(1));
    }
    return outArr.join(' ');
  }
  public isUrl(str: string): boolean {
    //return urlRegex.test(str);
    return !!str.match(urlRegex);
  }
  public reviveFunctions(key: string, value: any): any {
    if (typeof value === 'string' && value.includes('function')) {
    	return eval('(' + value.replace(/\\"/g, '"') + ')'); //unescape quotes
    }
    return value;
  }

  /**
   * A pure function to pick specific keys from object, similar to https://lodash.com/docs/4.17.4#pick
   * @param {Object}obj: The object to pick the specified keys from
   * @param {Array}keys: A list of all keys to pick from obj
   */
  public subset = (obj, keys) => 
  Object.keys(obj)
      .filter(i => keys.includes(i))
      .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
      }, {});

    public sortRainKeys(keys): any {
    keys.sort((a,b) =>{
        if (Number(a.substring(5,7)) > Number(b.substring(5,7))){
        return 1;
        } else {
        return -1;
        }
    });
    return keys
    }

public makeID(length) {
   let result = '';
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   const charLen = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charLen));
   }
   return result;
}
}
