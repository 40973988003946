import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Injector } from '@angular/core';
import {createCustomElement} from '@angular/elements'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common'; // Needed for use in componet/service .ts files and not only in templates

// #region Third Party Modules
import { NgbTooltipModule, NgbButtonsModule, NgbDropdownModule, NgbPopoverModule, NgbModalModule, NgbTabsetModule, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgDygraphsModule } from 'ng-dygraphs';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgProgressModule } from 'ngx-progressbar';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { CookieService } from 'ngx-cookie-service';
import { SidebarModule } from 'ng-sidebar';
// #endregion

// #region Services
import { HelperService } from './services/helper.service';
import { SharedService } from './services/shared.service';
// #endregion

// #region Components
import { MapComponent } from './components/map/map.component';
import { SearchComponent } from './components/search/search.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ControlsComponent } from './components/controls/controls.component';
import { ChartsComponent } from './components/charts/charts.component';
import { DatatablesComponent } from './components/datatables/datatables.component';
import { StationTypeSelectorComponent } from './components/station-type-selector/station-type-selector.component';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FavouritesComponent } from './components/favourites/favourites.component';
// #endregion


@NgModule({
  declarations: [
    MapComponent,
    SearchComponent,
    RegionSelectorComponent,
    StationTypeSelectorComponent,
    DatepickerComponent,
    ControlsComponent,
    ChartsComponent,
    DatatablesComponent,
    NotificationsComponent,
    FavouritesComponent
  ],
  entryComponents: [
    MapComponent,
    SearchComponent,
    RegionSelectorComponent,
    StationTypeSelectorComponent,
    DatepickerComponent,
    ControlsComponent,
    ChartsComponent,
    DatatablesComponent,
    NotificationsComponent,
    FavouritesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbAccordionModule,
    NgbTooltipModule, 
    NgbButtonsModule, 
    NgbDropdownModule, 
    NgbPopoverModule, 
    NgbModalModule,
    NgbTabsetModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgDygraphsModule,
    NgxDatatableModule,
    LeafletModule.forRoot(),
    Ng2AutoCompleteModule,
    NgSelectModule,
    //FlatpickrModule.forRoot(),
    Ng2FlatpickrModule,
    NgProgressModule,
    SidebarModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    DatePipe,
    HelperService,
    SharedService,
    CookieService
  ],
  schemas: []
})
export class AppModule {
  constructor( injector: Injector) {
    const map = createCustomElement(MapComponent,{injector});
    const search = createCustomElement(SearchComponent,{injector});
    const regionSelector = createCustomElement(RegionSelectorComponent,{injector});
    const stationTypeSelector = createCustomElement(StationTypeSelectorComponent,{injector});
    const datepicker = createCustomElement(DatepickerComponent,{injector});
    const controls = createCustomElement(ControlsComponent,{injector});
    const favourites = createCustomElement(FavouritesComponent,{injector});
    const charts = createCustomElement(ChartsComponent,{injector});
    const datatables = createCustomElement(DatatablesComponent,{injector});
    const notifications = createCustomElement(NotificationsComponent,{injector});

    customElements.define('mhl-map',map);
    customElements.define('mhl-search',search);
    customElements.define('mhl-region-selector',regionSelector);
    customElements.define('mhl-station-type-selector',stationTypeSelector);
    customElements.define('mhl-datepicker',datepicker);
    customElements.define('mhl-controls',controls);
    customElements.define('mhl-favourites',favourites);
    customElements.define('mhl-charts',charts);
    customElements.define('mhl-datatables',datatables);
    customElements.define('mhl-notifications',notifications);
  }
    ngDoBootstrap() {};
}