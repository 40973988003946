import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BaseOptions } from '../../../../node_modules/flatpickr/dist/types/options';
import { SharedService } from './../../services/shared.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'mhl-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {

  public showControls: boolean = true;
  public inline: boolean = false;
  public startDate: any;
  public defaultStart: any;
  public defaultEnd: any;
  public overrideStart: Date;
  public overrideEnd: Date;
  public mode: "single" | "multiple" | "range" | "time" = 'range';
  public theme: string = '';
  public allowInput: boolean = false;
  public currentDate: Date = new Date();
  public beginningDate: Date;
  public endDate: Date;
  public flatpickrOptions: Partial<BaseOptions>;
  @ViewChild('datePicker') public flatpickr: any;

  public dateRange: string = null;
  public selectedRange: {from: Date, to: Date};
  constructor(
    private _sharedService: SharedService,
    private _helperService: HelperService,
    private elRef: ElementRef,
    private datePipe: DatePipe
  ) {
    const domEl = elRef.nativeElement as HTMLElement;
    this.beginningDate = this.addDays(this.currentDate, -6);
    let showControls: string | boolean = domEl.getAttribute('showControls');
    showControls && showControls !== '' ? showControls = JSON.parse(showControls) : showControls = true;
    typeof showControls === 'boolean' ? this.showControls = showControls : this.showControls = true;

    let inline: string | boolean = domEl.getAttribute('inline');
    inline && inline !== '' ? inline = JSON.parse(inline) : inline = false;
    inline ? this.inline = true : this.inline = false;

    let startDate: any = domEl.getAttribute('startDate');
    if (startDate && startDate !== ''){
      try {
        this.overrideStart = new Date(startDate)
      } catch (e) {
        console.warn(`Cannot set startDate: ${startDate}`,e);
      }
    }
    let defaultStart: any = domEl.getAttribute('defaultStart');
    if (defaultStart && defaultStart !== ''){
      try {
        this.defaultStart = new Date(defaultStart)
      } catch (e) {
        console.warn(`Cannot set defaultStart: ${defaultStart}`,e);
      }
    }
    let defaultEnd: any = domEl.getAttribute('defaultEnd');
    if (defaultEnd && defaultEnd !== ''){
      try {
        this.defaultEnd = new Date(defaultEnd)
      } catch (e) {
        console.warn(`Cannot set defaultEnd: ${defaultEnd}`,e);
      }
    }
    //DEBUG this.overrideStart = new Date(this.currentDate.getDate() - 9999);
    let endDate: any = domEl.getAttribute('endDate');
    if (endDate && endDate !== ''){
      try {
        this.overrideEnd = new Date(endDate)
      } catch (e) {
        console.warn(`Cannot set endDate: ${endDate}`,e);
      }
    }
    let allowInput: any = domEl.getAttribute('allowInput');
    if (allowInput && allowInput !== ''){
        this.allowInput = _helperService.parseAttribute('boolean', allowInput, true, 'allowInput') as boolean;
    }

  }

  ngOnInit(): void {
    this.currentDate.setDate(this.currentDate.getDate() + 1); //set to start of next day to get all data for current day
    this.selectedRange = {
        from: this.defaultStart ? this.defaultStart : this.beginningDate,
        to: this.defaultEnd ? this.defaultEnd : this.currentDate
    };
    this.flatpickrOptions = {
          altInput: true,
          allowInput: this.allowInput,
          defaultDate: [this.selectedRange.from, this.selectedRange.to],
          inline: this.inline,
          maxDate: this.currentDate,
          minDate: this.beginningDate,
          mode: this.mode,
          onChange: this.onChange.bind(this)
    }
    this._sharedService.authenticated$.subscribe(auth => { 
      if (auth && this._sharedService.authToken){
        if (!this.overrideStart){
          this.startDate = this.addDays(this.currentDate, -this._sharedService.authToken.maxPeriod - 1);
          this.flatpickrOptions.minDate = this.startDate;
        } else {
          this.flatpickrOptions.minDate = this.overrideStart;
        }
        if (this.overrideEnd) {
          this.flatpickrOptions.maxDate = this.overrideEnd;
        }
        if (this.flatpickr.flatpickr){
          this.flatpickr.flatpickr.set('minDate',this.flatpickrOptions.minDate); 
          this.flatpickr.flatpickr.set('maxDate',this.flatpickrOptions.maxDate);
          this.flatpickr.flatpickr.setDate([this.selectedRange.from, this.selectedRange.to]);
        }
      }
    });
    this._sharedService.updateDates(this.defaultStart, this.defaultEnd); //set end to null to include today's date
  }
  ngAfterViewInit(): void { //hack to get full month range to display on init - still don't know why it doesn't init properly
    this.flatpickr.flatpickr.config.onOpen.push(()=>{
        this.flatpickr.flatpickr.changeYear(2019);
        this.flatpickr.flatpickr.changeYear(this.selectedRange.to.getFullYear());
    });
  
  }
  ngAfterViewChecked(): void { //fix year issue (still not sure where the span bmd-form-input comes from)
    const yearElement = document.querySelector('.numInputWrapper');
    let badChild = yearElement.childNodes[0];
    if (badChild.nodeName == 'SPAN'){
        const yearInput = badChild.firstChild;
        yearElement.insertBefore(yearInput,badChild);
        badChild.parentNode.removeChild(badChild);
        //badChild.remove() doesn't work in IE
    }
    const monthElement = document.querySelector('.flatpickr-current-month');
    badChild = monthElement.childNodes[0];
    if (badChild.nodeName == 'SPAN'){
        const monthInput = badChild.firstChild;
        monthElement.insertBefore(monthInput,badChild);
        badChild.parentNode.removeChild(badChild);
        //badChild.remove() doesn't work in IE
    }
  }
  public onChange(e): void {
      //console.log('event',e, e.length);
      if (this.mode == 'range' && e.length == 1){
        return;
      }
      if (e.length > 1){
        this.selectedRange = {
            from: e[0],
            to: e[1]
        }
      } else {
        this.selectedRange = {
            from: null,
            to: null
        };
      }
      //console.log('values',this.selectedRange);
  }
  public updateDates(): void {
    this.selectedRange.to.setDate(this.selectedRange.to.getDate()+1);
    //console.log(this.selectedRange);
    this._sharedService.updateDates(this.selectedRange.from, this.selectedRange.to);
  }

  public setDateRange(): void {
    // Set today range
    this.selectedRange = {
        from: this.defaultStart? this.defaultStart : this.beginningDate,
        to: this.defaultEnd ? this.defaultEnd : this.currentDate
    };
    this.flatpickr.flatpickr.setDate([this.selectedRange.from, this.selectedRange.to]);
    this._sharedService.updateDates(this.selectedRange.to, this.selectedRange.from); //set end to null to include today's date
  }
  public clearDateRange(): void {
    this.selectedRange = null;
    this.flatpickr.flatpickr.clear();
  }

  public addDays(date: Date, days: number){
    const dateCopy = new Date(date.getTime());
    dateCopy.setDate(dateCopy.getDate() + days);
    return dateCopy;
  }
}
