import { Component, OnInit, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Reading } from 'achelous';
import { SensorSummary } from 'achelous/dist/data/models/Site';

export interface datetruncOptions {
  datetrunc: string,
  sumtype: string,
  startDate?: string,
  endDate?: string
}

@Component({
  selector: 'mhl-datatables',
  templateUrl: './datatables.component.html',
  styleUrls: ['./datatables.component.sass']
})
export class DatatablesComponent implements OnInit {

  private domEl: HTMLElement;

  public limit: number = 10;
  public downloadable: boolean = false;
  public fillBg: boolean = false;
  public tableType: string;
  public summaryObject: any;
  public theme: string = '';
  public additionalClasses: string = 'col-12';
  public additionalOptions: datetruncOptions;
  public rowHeight: any = null;
  public headerHeight: number = 30;
  public footerHeight: number = 30;
  public defaultType: string;
  public defaultValue: any;
  public selectParams: string[];
  public overrideSites: string[];
  public parameterLabels: boolean = true;
  public dateFormat: string = 'h:mma dd/MM/yyyy';
  public dates: any;

  public readings: Reading;
  public tables: any[];
  public rawTables: any;
  public insufficientPermissions: boolean;

  private downloadUrl = '';
  private downloadUrlParsed: boolean;

  constructor(
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private _helperService: HelperService,
    private _sharedService: SharedService,
    private _http: HttpClient
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
    let limit: number | string = this.domEl.getAttribute('limit');
    limit && limit !== '' ? limit = JSON.parse(limit) : limit = 10;
    typeof limit === 'number' && limit > 1 ? this.limit = limit : this.limit = 10;

    let downloadable: string | boolean = this.domEl.getAttribute('downloadable');
    downloadable && downloadable !== '' ? downloadable = JSON.parse(downloadable) : downloadable = true; // TODO: implement default interface
    typeof downloadable === 'boolean' ? this.downloadable = downloadable : this.downloadable = true;

    let fillBg: string | boolean = this.domEl.getAttribute('fillBg');
    fillBg && fillBg !== '' ? fillBg = JSON.parse(fillBg) : fillBg = false; // TODO: implement default interface
    typeof fillBg === 'boolean' ? this.fillBg = fillBg : this.fillBg = false;

    const tableType = this.domEl.getAttribute('tableType');
    tableType && tableType !== '' ? this.tableType = tableType : this.tableType = '';

    const theme = this.domEl.getAttribute('theme');
    theme && theme !== '' ? this.theme = theme : this.theme = ''; // TODO: implement default interface

    const additionalClasses = this.domEl.getAttribute('additionalClasses');
    additionalClasses && additionalClasses !== '' ? this.additionalClasses = additionalClasses : this.additionalClasses = 'col-12'; // TODO: defaults?

    const rowHeight = this.domEl.getAttribute('rowHeight');
    rowHeight && rowHeight !== '' ? this.rowHeight = _helperService.parseAttribute('json', rowHeight, null, 'rowHeight') as JSON : this.rowHeight = null; // TODO: defaults?

    const headerHeight = this.domEl.getAttribute('headerHeight');
    headerHeight && headerHeight !== '' ? this.headerHeight = _helperService.toNumber(headerHeight) : this.headerHeight = 30; // TODO: defaults?

    const footerHeight = this.domEl.getAttribute('footerHeight');
    footerHeight && footerHeight !== '' ? this.footerHeight = _helperService.toNumber(footerHeight) : this.footerHeight = 30; // TODO: defaults?

    const additionalOptions = this.domEl.getAttribute('additionalOptions');
    additionalOptions && additionalOptions !== '' ? this.additionalOptions = JSON.parse(additionalOptions) as datetruncOptions : this.additionalOptions = undefined;
    const selectParams = this.domEl.getAttribute('selectParams');
    selectParams && selectParams !== '' ? this.selectParams = _helperService.parseAttribute('json', selectParams, [], 'selectParams') as string[] : this.selectParams = [];
    
    const overrideSites = this.domEl.getAttribute('overrideSites');
    overrideSites && overrideSites !== '' ? this.overrideSites = _helperService.parseAttribute('json', overrideSites, [], 'overrideSites') as string[] : this.overrideSites = [];
    
    const parameterLabels = this.domEl.getAttribute('parameterLabels');
    if (parameterLabels && parameterLabels !== ''){
        this.parameterLabels = _helperService.parseAttribute('boolean', parameterLabels, [], 'parameterLabels') as boolean;
    }

    const dateFormat = this.domEl.getAttribute('dateFormat');
    dateFormat && dateFormat !== '' ? this.dateFormat = dateFormat.trim() : this.dateFormat = 'h:mma dd/MM/yyyy';
    this.insufficientPermissions = false;

    const defaultType = this.domEl.getAttribute('defaultType');
    if (defaultType && defaultType !== '') {
      this.defaultType = defaultType;
    }
  }

  async ngOnInit(): Promise<void> {
    let selectParams = this._sharedService.selectParams;
    //console.log(selectParams);
    if (this.defaultType && this.defaultType !== ''){
        await this.addDefaultSelections();
        return;
    }
      if (this.tableType && this.tableType !==''){
        switch (this.tableType) {
            case 'latest':
            this.footerHeight = 0; //no footer needed on latest table
            if (this.overrideSites.length == 0){ 
            this._sharedService.selectedSitesForControls$.subscribe(async sites => {
              //DEBUG console.log('tableSites',sites);
              if (sites && sites.length > 0){
              let tables = [];
              const latest_vals = await this._sharedService.getLatestReadingsFromSite(sites); 
             
              const params = this._helperService.removeDuplicates(Object.values(latest_vals).map(v => v.unit_type));
              for (const p of params){
              if (this.selectParams.length > 0 && !this.selectParams.includes(p)){
                continue;
              }
              let fixedDec = 3;
              if (p === 'Precipitation' || p === 'Wave Height' || p === 'Wave Period' || p === 'Sea Temperature'){
                fixedDec = 1;
              } else if (p === 'Wave Direction'){
                fixedDec = 0;
              }
              const sensors = Object.keys(latest_vals).filter(s => latest_vals[s].unit_type == p).filter(s => !latest_vals[s].name.toLowerCase().includes('forecast'));
                  let cols = [{'name':'Site','prop':'site'}];
                  let rainDex = [];
                  if (p === 'Precipitation') {
                    rainDex = this._helperService.sortRainKeys(Object.keys(latest_vals[sensors[0]].value));
                    cols = [...cols,...rainDex.map((i, x) => ({'name':i, 'prop':String(x)}))];
                  } else {
                    cols = [...cols,{'name':'Latest Value','prop':'value'},{'name':'Timestamp','prop':'datetime'}];
                  }
                  const table: any = {
                    title: p,
                    parameter: p,
                    sensorIds: sensors,
                    units: latest_vals[sensors[0]].unit_label,
                    columns: cols,
                    rows: [],
                    downloadUrl: ''
                  };
                  sensors.forEach(s => {
                    const sensSite = sites.filter(x => x.sensor_ids.includes(s))[0];
                    let name = sensSite.name;
                    if (this.parameterLabels) {
                        name += ' (' + latest_vals[s].name + ')';
                    }
                    if (p === 'Precipitation'){
                        table.rows.push({'site': name,...rainDex.map(i => (latest_vals[s].value[i]).toFixed(fixedDec))});
                    } else {
                        table.rows.push({'site': name, 'value':(latest_vals[s].value).toFixed(fixedDec), 'datetime': this.datePipe.transform(latest_vals[s].timestamp, this.dateFormat, '+0000', 'en-AU')});
                    }
                  });
                  //console.log('table',table);
                  if (p == 'Water Level'){
                    tables.unshift(table); 
                  } else {
                    tables.push(table);
                  }
                }
                  if (this.selectParams && this.selectParams.length >0){
                    tables = this.selectParams.map(pm => tables.filter(tb => tb.parameter == pm)).flat();
                  }
              this.tables = tables;
              } else {
              this.tables = [];
              }
              });
            } else {
              let tables = [];
              const sites = await this._sharedService.getSitesFromSitecodes(this.overrideSites);
              const latest_vals = await this._sharedService.getLatestReadingsFromSite(sites); 
             
              const params = this._helperService.removeDuplicates(Object.values(latest_vals).map(v => v.unit_type));
              for (const p of params){
              if (this.selectParams.length > 0 && !this.selectParams.includes(p)){
                continue;
              }
              //const sensors = Object.keys(latest_vals).filter(s => latest_vals[s].unit_type == p).filter(s => !latest_vals[s].name.toLowerCase().includes('forecast'));
              const sensors = this.overrideSites.map(s => Object.keys(latest_vals).filter(sn => latest_vals[sn].unit_type == p && latest_vals[sn].sitecode == s).filter(sn => !latest_vals[sn].name.toLowerCase().includes('forecast'))).flat();
                  let cols = [{'name':'Site','prop':'site'}];
                  let rainDex = [];
                  if (p === 'Precipitation') {
                    rainDex = this._helperService.sortRainKeys(Object.keys(latest_vals[sensors[0]].value));
                    cols = [...cols,...rainDex.map((i, x) => ({'name':i, 'prop':String(x)}))];
                  } else {
                    cols = [...cols,{'name':'Latest Value','prop':'value'},{'name':'Timestamp','prop':'datetime'}];
                  }
                  const table: any = {
                    title: p,
                    parameter: p,
                    sensorIds: sensors,
                    units: latest_vals[sensors[0]].unit_label,
                    columns: cols,
                    rows: [],
                    downloadUrl: ''
                  };
                  sensors.forEach(s => {
                    const sensSite = sites.filter(x => x.sensor_ids.includes(s))[0];
                    let name = sensSite.name;
                    if (this.parameterLabels) {
                        name += ' (' + latest_vals[s].name + ')';
                    }
                    if (p === 'Precipitation'){
                        table.rows.push({'site': name,...rainDex.map(i => (latest_vals[s].value[i]).toFixed(1))});
                    } else {
                        table.rows.push({'site': name, 'value':(latest_vals[s].value).toFixed(3), 'datetime': this.datePipe.transform(latest_vals[s].timestamp, this.dateFormat, '+0000', 'en-AU')});
                    }
                  });
                  //console.log('table',table);
                  if (p == 'Water Level'){
                    tables.unshift(table); 
                  } else {
                    tables.push(table);
                  } 
                  }
                  if (this.selectParams && this.selectParams.length >0){
                    tables = this.selectParams.map(pm => tables.filter(tb => tb.parameter == pm)).flat();
                  }
                  this.tables = tables;
            }
            break;
            
        case 'series':
          this.rawTables = [].concat(this.defaultValue);
          this.tables = [];

          for (const rawTable of this.rawTables) {
            let downloadUrl;
            if (this.downloadable && this.downloadUrlParsed === false) {
              downloadUrl = this.downloadUrl.replace(/{PAGE}/, 'readings-series')
                .replace(/{QUERYPARAM}/, 'id')
                // .replace(/{QUERYVALUE}/, rawTable.summary.id.join(','))
                .replace(/{STARTDATE}/, Object.keys(rawTable.readings)[0].substring(0, 10))
                .replace(/{ENDDATE}/, Object.keys(rawTable.readings)[Object.keys(rawTable.readings).length - 1].substring(0, 10));
              // .replace(/{STARTDATE}/, this.defaultValue.summary.start.substring(0, 10))
              // .replace(/{ENDDATE}/, this.defaultValue.summary.start.substring(0, 10));
            } else {
              downloadUrl = this.downloadUrl;
            }

            const newRows: any[] = [];
            const table: any = {
              title: rawTable.summary.title && rawTable.summary.title !== '' ? rawTable.summary.title : 'Table', // TODO: change?
              columns: [],
              rows: [],
              downloadUrl: this.downloadUrl
            };
            rawTable.columns.forEach((column, index) => {
              table.columns.push({ prop: this._helperService.camelize(column) + index, name: column });
            });
            table.rows = Object.values(rawTable.readings);
            table.rows.forEach((row, index) => {
              const newRow: any = {};
              row.forEach((rowItem, itemIndex) => {
                newRow[table.columns[itemIndex].prop] = rowItem;
              });
              // console.log(newRow);
              if (newRow.every(isNaN)) {
                // console.log("Pop");
              } else {
                newRow.timestamp = this.datePipe.transform(Object.keys(rawTable.readings)[index].replace(' ', 'T'), this.dateFormat, '+0000', 'en-AU');
                newRows.push(newRow);
              }
            });
            table.rows = newRows;
            table.columns.unshift({ prop: 'timestamp' });
            // table.columns.push({ prop: 'timestamp' });
            // console.warn('table.rows', table.rows);
            this.tables.push(table);
          }
          break;
              
            default:
              console.error(`Unsupported 'tableType' ${this.tableType}. Please use one of the following 'summary', 'series'.\n\nIf you wish to use default sites data tables do not specify 'defaultType', 'defaultValue' and 'tableType' attributes\n\nTables will not be displayed`);
              }
      } else {
        this._sharedService.readings$.subscribe(async readings => {
          if (readings && readings.readings.length > 0){
          let tables = await this.getDatatablesFromReadings(readings);
          this.tables = tables;
          //console.log('tables', this.tables);
          if (this.dateFormat !== 'short') {
              let badInd = [];
              this.tables.forEach((table, index) => {
                if (selectParams.length > 0 && !selectParams.includes(table.parameter)){
                    //console.log("Excluding " + table.parameter);
                    badInd.push(index);
                    return;
                } else if (table.parameter.toLowerCase().includes('forecast')){
                    badInd.push(index);
                    return; 
                }

              table.rows.forEach(row => {
                row.timestamp = this.datePipe.transform(row.timestamp, this.dateFormat, '+0000', 'en-AU');
              });
            });
          }
        }
        });
      }    
  }

public async getDatatablesFromReadings(readings: Reading): Promise<any> {
  if (readings && readings.readings.length > 0) {
    if (this._sharedService.verbose) { console.time('getDatatablesFromReadings'); }
    const sensors = Object.keys(readings.meta);
    const datatables = [];
    const params = this._helperService.removeDuplicates(Object.values(readings.meta).map(o=>o.parameter));
    const paramSensorMap: any = {};
    params.map(p => paramSensorMap[p] = sensors.filter(s => readings.meta[s].parameter == p));

      for (const param of params) {
      const startDates = paramSensorMap[param].map(s => readings.meta[s].start);
      const endDates = paramSensorMap[param].map(s => readings.meta[s].end);
      const oldest = startDates.sort((a,b) => Date.parse(a) > Date.parse(b))[0];
      const newest = endDates.sort((a,b) => Date.parse(a) < Date.parse(b))[0];
      const anySensor = paramSensorMap[param][0];
      const set: any = {
        title: param,
        parameter: param,
        sensorIds: paramSensorMap[param],
        dates: {
          fromDate: oldest,
          toDate: newest
        },
        units: readings.meta[anySensor].unit,
        columns: [{ prop: 'timestamp' }, ...paramSensorMap[param].map(s => ({prop:s,name:readings.columns[s]}))],
        rows: [],
        downloadUrl: ''
      };
      
        for (const reading of readings.readings) {
          const paramReadings = this._helperService.subset(reading.values,paramSensorMap[param]);
          if (!Object.values(paramReadings).every(isNaN)){
            const ts = this.datePipe.transform(reading.timestamp.toISOString(), 'short', '+0000', 'en-AU');
            set.rows.push({timestamp: ts, ...paramReadings});
          }
        }
      datatables.push(set);
      }  
    //console.log('datatables', datatables);
    if (this._sharedService.verbose) { console.timeEnd('getDatatablesFromReadings'); }
    return datatables;
  }
}

private async addDefaultSelections(): Promise<void> {
    const attr = 'defaultType';
    const errorMessage = `You set the '${attr}' attribute value to '${this.defaultType}' but did not provide value for 'defaultValue' attribute.\n\nTables will not be displayed`;
    const defaultValue = this.domEl.getAttribute('defaultValue');
    switch (this.defaultType) {
        case 'url':
          if (this._helperService.isUrl(defaultValue)) {
            this.defaultValue = await this._http.get(defaultValue).toPromise();
            this.rawTables = this.defaultValue;
            if (this.downloadable) {
              const formatRegex = /format=json/;
              if (formatRegex.test(defaultValue)) {
                this.downloadUrl = defaultValue.replace(/format=json/, 'format=csv');
              } else {
                this.downloadUrl = defaultValue + '&format=csv';
              }
              this.downloadUrlParsed = true;
            } 
          } else {
            console.error(`The 'defaultValue' attribute value must be a url if 'defaultType' value is set to 'url'`);
          }
          break;
        case 'site-summary':
          this.defaultValue = defaultValue;
          if (!this.additionalOptions){
            this.additionalOptions = {datetrunc: 'day', sumtype: 'min,max,avg',startDate: '',endDate: ''};
          }
          const rawTables: SensorSummary = await this._sharedService.getSiteSummary(this.defaultValue,this.additionalOptions.datetrunc,this.additionalOptions.sumtype,this.additionalOptions.startDate,this.additionalOptions.endDate);
          this.rawTables = rawTables;
          this.tables = [];
          for (const sensor in rawTables) {
            const table: any = {
              title: rawTables[sensor].display_label + ' Summary',
              columns: [{ prop: 'timestamp' }],
              rows: [],
              downloadUrl: this.downloadUrl
            };
            rawTables[sensor].columns.forEach(column => {
              table.columns.push({ name: column });
            });
            table.rows = Object.values(rawTables[sensor].records);
            table.rows.forEach((row, index) => {
              row.timestamp = this.datePipe.transform(Object.keys(rawTables[sensor].records)[index].replace(' ', 'T'), 'dd/MM/yyyy', '+1000', 'en-AU');
            });
            this.tables.push(table);
          }
          if (this.selectParams && this.selectParams.length >0){
           this.tables = this.selectParams.map(pm => this.tables.filter(tb => tb.title.includes(pm))).flat();
          }

          //console.log(this.rawTables);
          break;

        default:
          this.defaultType = '';
          console.error(`Provided value for '${attr}' attribute is not supported, please use one of the following: 'url', 'object'\n\nTables will not be displayed`);
      }
  }
  /*
  public async generateDownloadLink(sensorIds: string[], fromDate: string, toDate: string): Promise<string> {
    const ret = await this._sharedService.generateDownloadLink(sensorIds, fromDate, toDate);
    return ret;
  }

  public download(input: any, content) {
    let url: string;
    this.modalService.open(content, {ariaLabelledBy: 'dl-dt-title', centered: true}).result.then(async (result) => {
        if (result == 'accept'){
            if (Array.isArray(input)){
                url = await this.generateDownloadLink(input[0],input[1],input[2]);
            } else {
                url = input;
            }  
          window.location.href = url;
        }
      });
  }
  */
}
