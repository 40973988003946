import { Component, OnInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { Site } from 'achelous/dist/data/models/Site';
import { SharedService, Favourites } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'mhl-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class FavouritesComponent implements OnInit {
  private domEl: HTMLElement;
  public loading: boolean = false;
  public favourites: Favourites = {sites: [], loadPlots: true, run: false};
  public favbar: any;
  public tableMessage: any;
  private firstRun: boolean = true;
  constructor(
    private elRef: ElementRef,
    private _sharedService: SharedService,
    public _helperService: HelperService,
  ) {
    this.domEl = this.elRef.nativeElement as HTMLElement;
    this.favbar = {opened: false,closeOutside: true,loading: false};
    this.tableMessage = {
        emptyMessage:  `No favourite sites`
    };
   }

  ngOnInit(): void {
    this._sharedService.isFavs = true;
    this.loading = true;
    this._sharedService.favourites$.subscribe(favourites => {
      if (favourites){
        this.domEl.setAttribute('noFavs',String(favourites.sites.length));
      }
      if (favourites && favourites.run){
        const sites = this.favourites.sites.map(s => s.sitecode);
        const newSites = favourites.sites.map(s => s.sitecode);
        if (!this._helperService.arrayMatch(sites,newSites)){
        const updateSites = this._helperService.diffArray(this.favourites.sites,favourites.sites); //find sites to add+remove
            this.favourites.sites = [...favourites.sites]; //need for change detection
            if (this.favourites.loadPlots) {
                this._sharedService.updateSelectedSitesForControls(updateSites);
            }
        }
      this.firstRun = false;
      this.loading = false;
      }
  });
  }
  public removeFavourite(fav: Site){
    //console.log(fav);
    this._sharedService.addSitecodeToFavourites(fav.sitecode); //this will add or remove
  }
  public clearFavourites(){
    if (this.favourites.sites.length == 0){
      return
    }
    this.favourites.sites = [];
    this._sharedService.clearFavourites(this.favourites.loadPlots);
  }
  public toggleSidebar() {
    this.favbar.opened = !this.favbar.opened;
  }

  public toggleOverride() {
    this.favourites.loadPlots = !this.favourites.loadPlots;
    this._sharedService.updateFavourites(this.favourites);
  }
}
