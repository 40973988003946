import { Component, ViewEncapsulation, AfterViewInit, ElementRef, Renderer2, OnInit, HostListener } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeWhile } from 'rxjs/operators';
import Dygraph from 'dygraphs';
import { Reading, Site } from 'achelous';
import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';

declare var Dygraph;

export interface annotations {
    value: number,
    displayText: string,
    hoverText?: string,
    parameter?: string,
    xval?: string,
    series?: string,
    color?: string,
    dash?: boolean,
    class?: string
}

@Component({
  selector: 'mhl-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.sass'],
  encapsulation: ViewEncapsulation.None
  })


export class ChartsComponent implements OnInit, AfterViewInit {

  private domEl: HTMLElement;

  private screenHeight: any;
  private screenWidth: any;
  public domBreak: string = '';
  private goodCharts: number = 0;
  public additionalClasses: string;
  public additionalLegendClasses: string;
  public height: any; // TODO: interface?
  public defaultType: string;
  public sync: boolean = true;
  public downloadable: boolean = false;
  public expandable: boolean = true;
  public defaultValue: any; // TODO: intrerface?
  public chartPlotterType: string;
  public mode: string;
  public externalLegend: boolean = false;
  public interval: string;
  public ahd: boolean = false;
  public sensorlabels: boolean;
  public parameterLabels: boolean = true;
  public parameterTitle: boolean = false;
  public ownership: string;
  public legendColumns: boolean;
  public selectParams: string[];
  public y2Param: string;
  public y2Offset: number;
  public startOverride: string;
  public endOverride: string;
  public barSeries: string;
  public options: object;
  public annotations: annotations[] = [];
  public loading: boolean = false;
  public singlePlot: boolean = false;
  public multiPlot: boolean = false;
  public staticPlot: boolean = false;
  public lockDates: boolean = false;

  public readings: Reading;
  public chartData: any[] = [];
  public data = [];
  public selectedSites: Site[] = [];

  private chartsRef: any = {};
  private syncedCharts: any[] = [];
  private changes: MutationObserver;

  public singleChart: any;
  public sensorIds: string[];
  public dates: any = {from: '', to: ''};

  @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      if (this.chartsRef && Object.keys(this.chartsRef).length > 0){
	const tempChart: Dygraph = Object.values(this.chartsRef)[0]
        this.updateDOMWidth(tempChart.graphDiv.offsetWidth);
      }
  }
  constructor(
    private elRef: ElementRef,
    private _helperService: HelperService,
    private _sharedService: SharedService,
    private modalService: NgbModal,
    private renderer: Renderer2
  ) {
    this.domEl = this.elRef.nativeElement as HTMLElement;
    this.onResize();

    const height = this.domEl.getAttribute('height');
    height && height !== '' ? this.height = _helperService.parseAttribute('json', height, { measure: 'vh', value: 35 }, 'height') as JSON : this.height = { measure: 'vh', value: 35 }; // TODO: defaults?

    const sync = this.domEl.getAttribute('sync');
    sync && sync !== '' ? this.sync = _helperService.parseAttribute('boolean', sync, true, 'sync') as boolean : this.sync = true;

    const startOverride = this.domEl.getAttribute('startDate');
    startOverride && startOverride !== '' ? this.startOverride = startOverride : this.startOverride = '';

    const endOverride = this.domEl.getAttribute('endDate');
    endOverride && endOverride !== '' ? this.endOverride = endOverride : this.endOverride = '';

    const interval = this.domEl.getAttribute('interval');
    interval && interval !== '' ? this.interval = interval : this.interval = '';

    const ahd = this.domEl.getAttribute('ahd');
    ahd && ahd !== '' ? this.ahd = _helperService.parseAttribute('boolean', ahd, false, 'ahd') as boolean : this.ahd = false;

    this.loadAttributes(_helperService);

    const chartPlotterType = this.domEl.getAttribute('chartPlotterType');
    if (chartPlotterType && chartPlotterType !== '') {
      switch (chartPlotterType) {
        case 'line':
          this.chartPlotterType = 'line';
          break;
        case 'linechart':
          this.chartPlotterType = 'line';
          break;
        case 'bar':
          this.chartPlotterType = 'bar';
          break;
        case 'barchart':
          this.chartPlotterType = 'bar';
          break;
        default:
          this.chartPlotterType = 'line';
      }
    } else {
      this.chartPlotterType = 'line';
    }

    const defaultType = this.domEl.getAttribute('defaultType');
    if (defaultType && defaultType !== '') {
      this.defaultType = defaultType;
    }
    const mode = this.domEl.getAttribute('mode');
    if (mode && mode !== '') {
      switch (mode) {
        case 'normal':
          this.mode = 'normal';
          break;
        case 'condense':
          this.mode = 'condense';
          break;
        case 'minimal':
          this.mode = 'minimal';
          break;
        default:
          this.mode = 'normal';
      }
    } else {
      this.mode = 'normal';
    }

    const staticPlot = this.domEl.getAttribute('staticPlot');
    staticPlot && staticPlot !== '' ? this.staticPlot = _helperService.parseAttribute('boolean', staticPlot, true, 'staticPlot') as boolean : this.staticPlot = false;
    const multiPlot = this.domEl.getAttribute('separatePlots');
    multiPlot && multiPlot !== '' ? this.multiPlot = _helperService.parseAttribute('boolean', multiPlot, true, 'multiPlot') as boolean : this.multiPlot = false;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach( (c: MutationRecord) => {
        if (c.attributeName.toLowerCase() == 'defaultvalue') {
          this.loading = true;
	        this.addDefaultSelections();
        }
      });
    //DEBUG console.log('mutations',mutations);
    if (this.staticPlot){
        this.loadAttributes(this._helperService);
        this.getChartsFromReadings(this.readings);
        this.renderChartData();
        }
    });
    }

  ngOnInit(): void {
    if (!(this.defaultType && this.defaultType !== '')){
      this._sharedService.isChart = true;
    }
    this.loading = true;
    if (this.startOverride){
        this.dates.from = this.startOverride;
    }
    if (this.endOverride){
        this.dates.to = this.endOverride;
    }
    if (this.startOverride && this.endOverride && !this.lockDates){
        console.warn('Both start and end dates set on chart component - this will lock the date range');
        this.lockDates = true;
    }
    if (!this.lockDates){
      	this._sharedService.dates$.subscribe(dates => {
            if (Object.keys(this.chartsRef).length > 0 ){
                this.loading = true;
            }
            if (dates){
      		    if (dates.fromDate && !this.startOverride) {
        		    this.dates.from = dates.fromDate;
      	        }
      	        if (dates.toDate && !this.endOverride) {
         		    this.dates.to = dates.toDate;
      		    }
           	    //DEBUG console.log("start date: " + dates.fromDate + " to end date : " + dates.toDate);
                //DEBUG console.log('this.dates', this.dates);
                if (this.defaultType && this.defaultType !== ''){
                    this.loading = true;
                    this.addDefaultSelections();
                }
            }
            //this.legendColumns = false;
        });
    } else if (this.defaultType && this.defaultType !== '') {
        this.addDefaultSelections();
    }
    if (!(this.defaultType && this.defaultType !== '')){
      this._sharedService.selectedSitesForCharts$.subscribe(sites => {
        this.loading = true;
        this.selectedSites = sites;
        //account for no active sensors
        const sensors = this.selectedSites.map(s => s.sensor_ids).flat();
        if (sites && sensors.length == 0 || (sensors.length == 1 && !sensors[0])){
            this.loading = false;
            this.chartData = [];
        }
      });
    }
    this.changes.observe(this.domEl,{attributes: true});
  }

  ngAfterViewInit(): void {
    this.listenTabs();
    if (!(this.defaultType && this.defaultType !== '')) {
      if (this.lockDates){
        this._sharedService.readings$.pipe(takeWhile(r => !(r && r.readings.length > 0), true)).subscribe(data => {
            this.updateChartsFromReadings(data);
        });
      } else {
        this._sharedService.readings$.subscribe(data => {
            this.updateChartsFromReadings(data);
        });
      }
    }
  }
  private updateChartsFromReadings(data: Reading): void {
    if (data && data.readings.length > 0){
      //DEBUG console.log('chartReadings',data);
      this.readings = data;
      this.loadAttributes(this._helperService);
      this.getChartsFromReadings(data,true);
      this.renderChartData();
    } else {
        this.loading = false;
        this.chartData = [];
    }
  }
  private syncCharts(): void {
    if (Object.keys(this.chartsRef).length === this.goodCharts && Object.keys(this.chartsRef).length > 1) {;
      Dygraph.synchronize(Object.values(this.chartsRef), { selection: true, zoom: true, range: false });
    }
  }
  private loadAttributes(_helperService: HelperService): void {
    const additionalClasses = this.domEl.getAttribute('additionalClasses');
    additionalClasses && additionalClasses !== '' ? this.additionalClasses = additionalClasses : this.additionalClasses = 'col-12'; // TODO: defaults?

    const additionalLegendClasses = this.domEl.getAttribute('additionalLegendClasses');
    additionalLegendClasses && additionalLegendClasses !== '' ? this.additionalLegendClasses = additionalLegendClasses : this.additionalLegendClasses = '';

    const downloadable = this.domEl.getAttribute('downloadable');
    downloadable && downloadable !== '' ? this.downloadable = _helperService.parseAttribute('boolean', downloadable, true, 'downloadable') as boolean : this.downloadable = true;
    
    const expandable = this.domEl.getAttribute('expandable');
    expandable && expandable !== '' ? this.expandable = _helperService.parseAttribute('boolean', expandable, true, 'expandable') as boolean : this.expandable = true;

    const externalLegend = this.domEl.getAttribute('externalLegend');
    externalLegend && externalLegend !== '' ? this.externalLegend = _helperService.parseAttribute('boolean', externalLegend, false, 'externalLegend') as boolean : this.externalLegend = false; // TODO: defaults?

    const selectParams = this.domEl.getAttribute('selectParams');
    selectParams && selectParams !== '' ? this.selectParams = _helperService.parseAttribute('json', selectParams, [], 'selectParams') as string[] : this.selectParams = [];
    //this._sharedService.selectParams = this.selectParams;

    const y2Param = this.domEl.getAttribute('y2Param');
    y2Param && y2Param !== '' ? this.y2Param = y2Param : this.y2Param = '';
    if (this.y2Param && !(this.selectParams && this.selectParams.length == 2)){
      console.log("y2Param only works when 2 parameters are selected - please update 'selectParams' attribute and try again");
      this.y2Param = '';
    } else if (this.y2Param && !this.selectParams.includes(this.y2Param)){
      console.log("y2Param must be one of the selected parameters - please update and try again");
      this.y2Param = '';
    }

    const y2Offset = this.domEl.getAttribute('y2Offset');
    y2Offset && y2Offset !== '' ? this.y2Offset = Number(y2Offset) : this.y2Offset = null;

    const options = this.domEl.getAttribute('options');
    options && options !== '' ? this.options = _helperService.parseAttribute('json', options, {}, 'options') as JSON : this.options = {}; // TODO: defaults?

    const annotations = this.domEl.getAttribute('thresholds');
    if (annotations && annotations !== ''){
        const anno = _helperService.parseAttribute('json', annotations, [], 'annotations') as JSON;
        if (Array.isArray(anno)){
            this.annotations = [];
            anno.forEach(el => {
                this.annotations.push(el as annotations);
            });
        }
        this.annotations.filter(a => {
            a.parameter = a.parameter ? a.parameter : 'Water Level'; //set default
            a.hoverText = a.hoverText ? a.hoverText : '';
        });
    }
    const barSeries = this.domEl.getAttribute('barSeries');
    barSeries && barSeries !== '' ? this.barSeries = barSeries : this.barSeries = '';

    const sensorlabels = this.domEl.getAttribute('sensorlabels');
    sensorlabels && sensorlabels !== '' ? this.sensorlabels = _helperService.parseAttribute('boolean', sensorlabels, true, 'sensorlabels') as boolean : this.sensorlabels = false;

    const singlePlot = this.domEl.getAttribute('singlePlot');
    if (singlePlot && singlePlot !== ''){
        this.singlePlot = _helperService.parseAttribute('boolean', singlePlot, true, 'singlePlot') as boolean;
    }

    const lockDates = this.domEl.getAttribute('lockDates');
    if (lockDates && lockDates !== '') {
        this.lockDates = _helperService.parseAttribute('boolean', lockDates, true, 'lockDates') as boolean;
    }

    const parameterLabels = this.domEl.getAttribute('parameterLabels');
    if (parameterLabels && parameterLabels !== '') this.parameterLabels = _helperService.parseAttribute('boolean', parameterLabels, true, 'parameterLabels') as boolean;

    const parameterTitle = this.domEl.getAttribute('parameterTitle');
    if (parameterTitle && parameterTitle !== '') this.parameterTitle = _helperService.parseAttribute('boolean', parameterTitle, true, 'parameterTitle') as boolean;

    const ownership = this.domEl.getAttribute('attribution');
    if (ownership && ownership !== '') this.ownership = ownership;

  }
  public toggleVisibility(chartId: string, seriesIndex: number, visibility: boolean): void {
      this.chartsRef[chartId].setVisibility(seriesIndex, visibility);
  }

  private async fetchReadingUrl(url: string): Promise<Reading> {
    const response = await fetch(url).then(ret => {
        if (!ret.ok){
            console.warn('Url not correctly loaded. Return status: ', ret.status);
            return {summary:{}, stats:{}, columns:{}, readings:{}};
        } else {
            return ret.json();
        }
    });
    return new Reading(response);
  }
  private async renderChartData(): Promise<void> {
    this.loading = true;
    this.chartsRef = {};
    if (this.chartData.length > 0) {
      let inds = [];
      let i = 0;
      this.goodCharts = this.chartData.length;
      for (const chart of this.chartData as any) {
        //DEBUG console.log('chart:',chart);
          if (chart.values.length == 0){
              chart.isData = false;
              this.goodCharts -= 1;
	      i++;
              continue;
          }
          chart.isData = true;
          if (this.selectParams.length >0 && !this.selectParams.includes(chart.parameter)){
          //DEBUG console.log('parameter not included:',chart.parameter);
              this.goodCharts -= 1;
              inds.push(chart.id);
	      i++;
              continue;
          }
          let dygraph;
          if (this.externalLegend) {
          chart.options.labelsDiv = `chart-legend-${chart.id}`;
          }
          if (this.parameterTitle) {
            //assumes all series use same parameter - should not be used otherwise
            const param = chart.altNames[0];
            //avoid titles like 'Direction (Dir)'
            if (!chart.options.title.toLowerCase().includes(param.toLowerCase())){
                chart.options.title = chart.options.title + ' (' + chart.altNames[0] + ')';
            }
          }
          if (this.sensorlabels) {
              chart.options.labels = ['Dates'].concat(chart.altNames);
              let temp = chart.seriesNames;
              chart.seriesNames = chart.altNames;
              chart.altNames = temp;
          } else if (!this.parameterLabels){
              chart.options.labels = chart.options.labels.map(l => {
                  const lab = l.split(' (');
                  if (lab.length > 1){
                      return lab.slice(0,-1).join(' (');
                  } else {
                    return l;
                  }
              });
          }
          if (chart.plotType === 'bar' || this.chartPlotterType === 'bar') {
              chart.options.plotter = this.multiColumnBarPlotter.bind(this);
          }
          if (Object.keys(this.options).length > 0) {
              chart.options = { ...chart.options, ...this.options };
          }
          if (this.screenWidth < 992) {
              chart.options.interactionModel = {};
          }
          if (chart.options.labelsSeparateLines) {
              this.legendColumns = true;
          }
      setTimeout(() => { // Timeout is needed to output charts in order and right after the template is prepared
	      const chartsLength = Object.keys(this.chartsRef).length;
        chart.options.legendFormatter = this.defaultDygraphLegend.bind([chart.id,chart.annotations.length]), //need to account for removed charts in above script
        chart.options.zoomCallback = () => {
            this.updateAnnotations.bind([this,chart.annotations,chartsLength]);
            chart.dygraph.updateOptions({file: ''}); //updateOptions to allow correct vertical zooming
        }
        const chartDiv: HTMLElement = this.domEl.querySelector('#chart-' + chart.id); 
	      dygraph = new Dygraph(chartDiv, chart.values, chart.options);
        this.chartsRef[chart.id] = dygraph;
        //DEBUG console.log('dygraph_object',dygraph);
        chart.dygraph = dygraph;
        if (this.sync) {
          this.syncCharts();
        }
        if (chart.annotations.length > 0){
          dygraph.ready(c=> this.updateAnnotations('','',[this,chart.annotations,chartsLength,dygraph]));
        }
        if (!this.domBreak || this.domBreak == ''){
            this.updateDOMWidth(chartDiv.offsetWidth);
        }
        }, 200);
      i++;
   }
   inds.reverse()
   for (const idx of inds as any){
      this.chartData.splice(idx,1);
   }
  }
  this.loading = false;
  }

  private async addDefaultSelections(): Promise<void> {
    const type = this.defaultType;
    const attr = 'defaultValue';
    const errorMessage = `You set the '${attr}' attribute value to '${type}' but did not provide value for 'defaultValue' attribute.\n\nNo sites will be preselected`;
    this.defaultValue = this.domEl.getAttribute('defaultValue');
    if (this.defaultValue == 'wait'){ //wait for attribute to be update in js
      //DEBUG console.log('waiting',this.loading);
      return;
    }
    if (this.defaultValue && this.defaultValue !== '') {
      this.defaultType = type;
      this.loadAttributes(this._helperService);
      let chartData = this.defaultValue;
      if (chartData.toLowerCase().includes('sessionstorage')){
        const key = chartData.split('.').slice(-1)[0]; //format is sessionstorage.key
        chartData = sessionStorage.getItem(key);
	try {
          chartData = JSON.parse(chartData);
    	}
    	catch(e){
          console.warn("Unable to parse session token as JSON - assuming csv",chartData);
        }
   
      }
    //DEBUG console.log(chartData);
      switch (type) {
        case 'url-csv':
          if (this._helperService.isUrl(this.defaultValue)) {
            this.defaultValue = this.defaultValue;
            this.singleChart = {};
            this.singleChart.id = Math.random().toString(36).substring(7); 
            const seriesOpts = {}
	    this.annotations.forEach((a,i) =>{
              seriesOpts[a.series] = {color: a.color ? a.color : 'red', strokePattern: a.dash ? [4,4] : null};
            });
	    
	    this.singleChart.options = {
              digitsAfterDecimal: 3,
              colors: ['#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#a65628', '#f781bf', '#e41a1c'],
	      legendFormatter: this.defaultDygraphLegend.bind([this.singleChart.id,this.annotations.length]),
              drawCallback: this.addLegendListeners.bind(this),
              unhighlightCallback: this.addLegendListeners.bind(this),
              zoomCallback: this.updateAnnotations.bind([this,this.annotations,this.singleChart.id]),
	      series: seriesOpts,
              plugins: [
                this.doubleClickZoomOutPlugin
              ]
            }; 
            this.chartsRef = {};
            if (this.externalLegend) {
              this.singleChart.options.labelsDiv = `chart-legend-${this.singleChart.id}`;
              this.singleChart.options.labelsSeparateLines = true;
            }
            if (this.singleChart.plotType === 'bar') {
              this.singleChart.options.plotter = this.multiColumnBarPlotter.bind(this);
              
            }
            if (this.chartPlotterType === 'bar') {
              this.singleChart.options.plotter = this.multiColumnBarPlotter.bind(this);
            }
            if (Object.keys(this.options).length > 0 && this.options.constructor === Object) {
              /*if (this.annotations.length > 0 && !('valueRange' in this.options)) {
	      	let maxVal = 0;
		this.annotations.forEach(a =>{
		    if (a.value > maxVal){
		        maxVal = a.value + 0.2;
		    }
		});
		//this.options['valueRange'] = [null,maxVal];
	      }*/
              this.singleChart.options = { ...this.singleChart.options, ...this.options };
            }
            if (this.singleChart.options.labelsSeparateLines) {
              this.legendColumns = true;
            }
            if (this.mode === 'condense' || this.mode === 'minimal') {
                this.singleChart.showTools = false;
            } else {
                this.singleChart.showTools = true;
            }
            // console.log('this.singleChart', this.singleChart);
            // console.log(this.singleChart.options);
            this.loading = false; 
	    setTimeout(() => {
              const chartDiv: HTMLElement = this.domEl.querySelector(`#chart-${this.singleChart.id}`)
	      const g = new Dygraph(chartDiv, chartData, this.singleChart.options);
              this.singleChart.dygraph = g;
              this.chartsRef[this.singleChart.id] = g;
              this.singleChart.options.legendFormatter = this.defaultDygraphLegend.bind([this.singleChart.id,this.annotations.length]);
              if (this.annotations.length > 0){
                g.ready(c=> this.updateAnnotations('','',[this,this.annotations,1,g]));
              } 
              this.singleChart.downloadUrl = this.downloadable ? this.defaultValue : '';
              if (!this.domBreak || this.domBreak == ''){
                  this.updateDOMWidth(chartDiv.offsetWidth);
              }
              if (this.sync) {
                this.syncCharts();
              }
              // console.warn(g.attributes_.labels_);
            }, 0);
          } else {
            console.error(`'this.defaultValue' attribute must be a url if 'defaultType' is set to 'url-csv': ` + this.defaultValue);
          }
          break;

          case 'url-json':
            if (this.defaultValue.toLowerCase().includes('sessionstorage')){
	      this.readings = new Reading(chartData);
            } else if (this._helperService.isUrl(this.defaultValue)) {
              this.readings = await this.fetchReadingUrl(this.defaultValue);
              //DEBUG console.log('chart readings',this.readings);
	    }
            if (this.readings){
              this.getChartsFromReadings(this.readings,true,this.singlePlot,this.multiPlot);
              this.renderChartData();
            } else {
              console.error(`'this.defaultValue' attribute nust be a url if 'defaultType' is set 'url'.`);
            }
          break;

        case 'sensor-ids':
          this.defaultValue = this._helperService.parseAttribute('json', this.defaultValue, null, 'this.defaultValue') as JSON; // TODO: defaults?
          if (Array.isArray(this.defaultValue)) {
            this.sensorIds = this.defaultValue.map(s => String(s));
          } else if (Array.isArray(this.defaultValue.split(','))) { // Just checking if the this.defaultValue was already passed a string separated by commas
              this.sensorIds = this.defaultValue.split(',').map(s => String(s));
          }
          if (this.sensorIds.length == 1 && this.sensorIds[0].includes(',')) { //catch improperly formatted json
            this.sensorIds = this.sensorIds[0].split(',');
          }
          if (this.sensorIds && this.sensorIds.length > 0) {
            this.loading = true;
            this.readings = await this._sharedService.getReadingsFromSensors(false,this.sensorIds,{fromDate: this.dates.from, toDate: this.dates.to},this.interval,this.ahd);
            this.getChartsFromReadings(this.readings,true,this.singlePlot,this.multiPlot);
            if (this.multiPlot){ //order plots by give sensor_id order
                this.chartData = this.sensorIds.map(s => {
                    const found = this.chartData.filter(c => c.parameter == s);
                    if (found.length >0){
                        return found[0];
                    }
                    return;
                });
            }
            this.renderChartData();
          } else {
            console.error(`Invalid value for 'this.defaultValue'! If 'defaultType' is set to 'sensor-ids' then the 'this.defaultValue' must be a an array of strings with sensor ids or a string of ids separated by commas.\n\nNo sites will be preselected`);
            this.loading = false;
            this.chartData = [];
          }
          break;

        default:
          this.defaultType = '';
          console.error(`Provided value for '${attr}' attribute is not supported, please use one of the following: 'url-csv', 'sensor-ids'\n\nNo sites will be preselected`);
      }
    } else {
      console.error(errorMessage);
      this.loading = false;
      this.chartData = [];
    }

  }

  public async generateDownloadLink(sensorIds: string[], fromDate: string | Date, toDate: string | Date, interval: string): Promise<string> {
      const dlLink = await this._sharedService.generateDownloadLink(sensorIds, fromDate, toDate, interval);
      //DEBUG console.log(dlLink);
      return dlLink;
  }


  public getChartsFromReadings(allReadings: Reading, override: boolean = true, single: boolean = false, multi: boolean = false): any {
    //console.log('chartreadings',allReadings);
    if (this._sharedService.verbose) { console.time('getChartsFromReadings'); }
    let meta = allReadings.meta;
    //if (!meta && 'summary' in (allReadings as any)){
    //  meta = (allReadings as any).summary;
   //}
    const stats = allReadings.stats;
    //DEBUG console.log('meta',meta);
    if (single){
        Object.keys(meta).forEach(k => meta[k]['parameter'] = 'single');
    }
    if (multi){
        Object.keys(meta).forEach(k => meta[k]['parameter'] = String(k));
    }
    const chartParams = this._helperService.removeDuplicates(Object.values(meta).map(o=>o.parameter));
    let chartData = [];
    const chartDataMap = {};
    const sensorLabelMap = allReadings.columns;
    const sensorParamMap = {};
    let chartDex: number = 0;
    for (const p of chartParams){
      if (this.y2Param && this.y2Param == p){
        continue;
      }
      let inds = [];
      let y2inds = [];
      let y2Sensors = [];
      Object.values(meta).forEach((o,idx) => o.parameter == p ? inds.push(idx) : null);
      if (this.y2Param){
        Object.values(meta).forEach((o,idx) => o.parameter == this.y2Param ? y2inds.push(idx) : null);
        Object.values(meta).forEach((o,idx) => o.parameter == this.y2Param ? inds.push(idx) : null);
        y2Sensors = y2inds.map(i => Object.keys(meta)[i]);
      }
      let paramSensors = inds.map(i => Object.keys(meta)[i]);
      if (this.sensorIds){
        paramSensors = this.sensorIds.filter(s => paramSensors.includes(s));
        //DEBUG console.log('sensor-id chart',this.sensorIds,paramSensors);
      } else if (this.selectedSites.length > 0){
        const sitecodes = this.selectedSites.map(site => site.sitecode);
        paramSensors = sitecodes.map(ss => paramSensors.filter(s => meta[s].sitecode == ss)).flat();
        //DEBUG console.log('sitecode chart',this.selectedSites,paramSensors);
      }
      sensorParamMap[p] = paramSensors;
      const yaxSensors = paramSensors.filter(s => !y2Sensors.includes(s));
      const chartAnnos = this.annotations.filter(a => a.parameter == p);
      const annoOptions = {};
      chartAnnos.forEach((a,i) =>{
        a.series = p + '_' + String(i);
        annoOptions[a.series] = {color: a.color ? a.color : 'red', strokePattern: a.dash ? [4,4] : null};
        });
      if (this.y2Offset && this.y2Offset !== 0){
        annoOptions['y2Series'] = {"axis": "y2", "strokeWidth":0 };
        chartAnnos.push({value: this.y2Offset, displayText: "", series: "y2Series", class: "d-none"});
      }
      let unit = meta[paramSensors[0]].unit;
      let datums = null;
      if (p == 'Water Level'){ //add datum info to unit label
        datums = Array.from( new Set(paramSensors.map(s => meta[s].datum).filter(s=> s && s !== 'None')));
        //console.log(datums);
        if (datums.length == 1){
          unit += ' ' + datums[0];
        }
        unit += '<sup><i class="fa fa-info-circle" (click)="' + 'this.modalOpen("datum_content","")' +'"></i></sup>';
      }
      const names = paramSensors.map(s => sensorLabelMap[s]).concat(chartAnnos.map(a => a.series));
      const displayLabs = paramSensors.map(s => meta[s].display_label ? meta[s].display_label : meta[s].id).concat(chartAnnos.map(a => a.series));
      let barIndex = names.indexOf(this.barSeries);
      if (barIndex < 0) {
          barIndex = displayLabs.indexOf(this.barSeries);
        }
      if (this.sensorlabels && barIndex >= 0){
            this.barSeries = displayLabs[barIndex];
          }
      if (barIndex >=0){
          annoOptions[this.barSeries] = {"axis":"y2","plotter": this.barChartPlotter.bind(this)};
      } else if (this.y2Param){
        const y2names = y2Sensors.map(s => sensorLabelMap[s]).concat(chartAnnos.map(a => a.series));
        y2names.forEach(n =>{
        annoOptions[n] = {"axis":"y2", "plotter":Dygraph.Plotters.linePlotter};
        });
      }
      //const altNames = paramSensors.map(s => meta[s].id);
      const annoSeries = chartAnnos.map(a=>a.series);
      if (!chartDataMap[p]) {
      const chartId = !this.defaultType ? this._helperService.camelize(p) : this._helperService.makeID(8);
      const chartParam = {
            id: chartId,
            parameter: p,
            datums: datums,
            sensorids: paramSensors,
            seriesNames: names,
            altNames: displayLabs,
            dates: this.dates,
            downloadUrl: '',
            plotType: p == 'Precipitation' ? 'bar' : 'line',
            annotations: chartAnnos,
            options: {
              labels: ['Dates'].concat(names),
              labelsUTC: true,
              //labelsKMB: true,
              ylabel: (multi ? displayLabs[0] : p) + ' (' + unit + ')',
              title: multi ? names[0] : this._helperService.titleCase(p),
              digitsAfterDecimal: 3,
              valueRange: this.calculatePlotRange(yaxSensors.map(s=>meta[s].plot_range)),
              colors: paramSensors.length > 1 ? ['#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#a65628', '#f781bf', '#e41a1c'] : ['#377eb8'],
              //colors: paramSensors.length > 1 ? ['#00ccff','#6600cc','#000099','#ff6600','#006600','#99cc00','#ff1a1a'] : ['#377eb8'],
              legendFormatter: this.defaultDygraphLegend.bind([chartId,chartAnnos.length]), //binds chart array index to 'this' in legendformatter for later reference
              drawCallback: this.addLegendListeners.bind(this),
              drawHighlightPointCallback: this.drawPoints.bind(annoSeries),
              unhighlightCallback: this.addLegendListeners.bind(this),
              zoomCallback: this.updateAnnotations.bind([this,chartAnnos,chartDex]),
              plugins: [
                this.doubleClickZoomOutPlugin
              ],
              series: annoOptions,
              axes: { y2: (this.barSeries || this.y2Param) ? {independentTicks:true, drawGrid:false, axisLabelWidth: 45, valueRange: this.calculatePlotRange(y2Sensors.map(s=>meta[s].plot_range)),} : {},
                       y: stats ? {axisLabelWidth: Math.max(...paramSensors.map(s=>stats[s].max)) >= 1000 ? 80 : 50} : {axisLabelWidth:50}
              },
              animatedZooms: true,
              connectSeparatedPoints: true,
              strokeWidth: 1.5,
              pointSize: 2,
              rightGap: (this.barSeries || this.y2Param) ? -10 : 10
            },
            values: [],
            dygraph: null,
            expanded: false
            };
            if (p.includes('Wave') || p.includes('Sea')){
                //chartParam.options.colors = paramSensors.length > 1 ? ['#000099','#ff6600','#006600','#00ccff','#ff1a1a','#6600cc','#99cc00'] : ['#377eb8'];
                chartParam.options.colors = paramSensors.length > 1 ? ['#377eb8', '#ff7f00', '#4daf4a','#f781bf', '#a65628', '#984ea3','#999999'] : ['#377eb8'];
                chartParam.options.connectSeparatedPoints = false;
            }
            if (chartParam.dates.from || chartParam.dates.to) {
            this.generateDownloadLink(paramSensors,chartParam.dates.from,chartParam.dates.to,this.interval).then(v => chartParam.downloadUrl = v);
          } else {
          this.generateDownloadLink(paramSensors,'','',this.interval).then(v => chartParam.downloadUrl = v);
          }
          //DEBUG console.log(chartParam);
          if (p == 'Water Level'){
            chartData.unshift(chartParam);
          } else {
            chartData.push(chartParam);
          }
          chartDataMap[p] = true;
          chartDex++;
        }
      }; /////////
      //console.log('sensorParamMap', sensorParamMap);
      // console.log('paramSiteMap', paramSiteMap);

      if (allReadings.readings.length > 0) {
        // console.log('chart map',chartDataMap);
        const readingsData = allReadings.readings;
        for (const reading of readingsData) {
          chartData.forEach(cd => {
            const tsValues = cd.sensorids.map(s => reading.values[s]);
            if (!tsValues.every(x => {return !x ? x !== 0 : false;}) || this.interval){
              if (cd.annotations.length > 0){
                cd.values.push([reading['timestamp'],...tsValues,...cd.annotations.map(s => s.value)]);
              } else {
                cd.values.push([reading['timestamp'],...tsValues]);
              }
            }
          });
          }
          if (this.selectParams && this.selectParams.length >0){ //reorder charts to match selectParams
          chartData = this.selectParams.map(pm => chartData.filter(ch => ch.parameter == pm)).flat();
          }
        if (override){
          this.chartData = chartData;
        }

      if (this._sharedService.verbose) { console.timeEnd('getChartsFromReadings'); }
    }
    return chartData;
  }

  public calculatePlotRange(ranges:number[][]): number[] {
    const firstCol = ranges.map(a=>a[0]);
    const secCol = ranges.map(a=>a[1]);
    let out = [null,null];
    if (firstCol.every(v => v === null) === false){
       out[0] = Math.min(...firstCol);
    }
    if (secCol.every(v => v === null) === false){
        out[1] = Math.max(...secCol);
    }
    return out;
  }

public multiColumnBarPlotter(e): any {
  if (e.seriesIndex !== 0) { return; }
  if (e.seriesCount === 1) {
    return this.barChartPlotter(e);
  }
  //DEBUG console.log('e', e);
  const g = e.dygraph;
  const ctx = e.drawingContext;
  const labels = g.attributes_.labels_;
  const series = g.attributes_.series_;
  const sets = e.allSeriesPoints.filter(p => !series[p[0].name].options.hasOwnProperty('plotter'));
  //console.log('sets',sets);
  const y_bottom = e.dygraph.toDomYCoord(0);
  // Find the minimum separation between x-values.
  // This determines the bar width.
  let min_sep = Infinity;
  for (let j = 0; j < sets.length; j++) {
    const points = sets[j];
    for (let i = 1; i < points.length; i++) {
      const sep = points[i].canvasx - points[i - 1].canvasx;
      if (sep < min_sep) { min_sep = sep; }
    }
  }
  const bar_width = Math.floor(2.0 / 3 * min_sep);

  const fillColors = [];
  const strokeColors = g.getColors();
  for (let i = 0; i < strokeColors.length; i++) {
    fillColors.push(this.darkenColor(strokeColors[i]));
  }

  for (let j = 0; j < sets.length; j++) {
    ctx.fillStyle = fillColors[j];
    ctx.strokeStyle = strokeColors[j];
    for (let i = 0; i < sets[j].length; i++) {
      const p = sets[j][i];
      const center_x = p.canvasx;
      const x_left = center_x - (bar_width / 1.3) * (1 - j / (sets.length - 1));

      ctx.fillRect(x_left, p.canvasy,
        bar_width / sets.length, y_bottom - p.canvasy);

      ctx.strokeRect(x_left, p.canvasy,
        bar_width / sets.length, y_bottom - p.canvasy);
    }
  }
}
public modalOpen(content,data) {
    var file_name = 'mhl-data.csv'
    if (this.singleChart.options['title']){
      file_name = this.singleChart.options['title'] + '.csv'
    }
    this.modalService.open(content, {ariaLabelledBy: content + '-title', centered: true }).result.then((result) => {
      if (result == 'accept'){
        if (data.downloadUrl.includes('sessionStorage')){
          // Create CSV file and temporary link
          const blob = new Blob([data], { type: "text/csv" });
          var temp_link = document.createElement('a');
          // Download csv file
          temp_link.download = file_name;
          var url = window.URL.createObjectURL(blob);
          temp_link.href = url;
          temp_link.style.display = "none";
          document.body.appendChild(temp_link);
          temp_link.click(); // Automatically trigger download
          document.body.removeChild(temp_link);
        }else{
          window.location.href = data.downloadUrl;
        }
      }
    });
  }
public defaultDygraphLegend(data) {
let chartId, annoLength
if (Array.isArray(this)){
    [chartId,annoLength] = this;
}
var g = data.dygraph;
var sepLines = g.getOption('labelsSeparateLines');
var legend = g.getOption('legend');
var html;
if (typeof data.x === 'undefined') {
  if (legend !== 'always') {
    return '';
  }
  html = "<b>Legend:  </b>";
  for (var i = 0; i < data.series.length - annoLength; i++) {
    var series = data.series[i];
    series.labelHTML = series.labelHTML.replace(/ /g,"&nbsp;");
    if (!series.isVisible){
    html += "<span class='legend-item' visible='false' chart=" + chartId + " position=" + i + " style='font-weight: bold; color: #a6a6a6; opacity: 0.8;cursor:pointer;'>" + "<input type='checkbox'/> " + series.labelHTML + "</span>";
    } else {
      html += "<span class='legend-item' visible='true' chart=" + chartId + " position=" + i + " style='font-weight: bold; color: " + series.color + ";cursor:pointer;'>" + "<input type='checkbox' checked/> " + series.labelHTML + "</span>";
    }
    if (html !== '' && i < data.series.length-1) html += sepLines ? '<br/>' : ' ';
  }
  return html;
}

html = "<b>" + data.xHTML + ':</b>';
if (sepLines && legend !== 'always') html += '<br>';
for (var i = 0; i < data.series.length - annoLength; i++) {
  var series = data.series[i];
    if (!series.yHTML){
      series.yHTML = ' - ';
      }
  series.labelHTML = series.labelHTML.replace(/ /g,"&nbsp;");
  var cls = series.isHighlighted ? ' class="highlight"' : '';
  if (!series.isVisible){
    html += "<span class='legend-item' visible='false' position=" + i + " style='font-weight: bold; color: #a6a6a6; opacity: 0.8;cursor:pointer;'>" + series.dashHTML + " " + series.labelHTML + "</span>";
  } else {
    html += "<span" + cls + "> <b><span style='color: " + series.color + ";'>" + series.labelHTML + "</span></b>:&#160;" + series.yHTML + "</span>";
  }
  if (sepLines && i < data.series.length-1) html += '<br>';
}
return html;
}

public addLegendListeners(e: any): void {
  this.loading = false;
  setTimeout( ()=> { //need timeout to wait for legend-items to be added to canvas
    const items = document.getElementsByClassName('legend-item');
    Array.from(items).forEach(el => {
      const chartId = el.getAttribute('chart');
      const chart = this.chartsRef[chartId];
      if (!chart){
          return;
      }
      const pos = el.getAttribute('position');
      let vis: any = el.getAttribute('visible');
      if (vis == 'false'){
        vis = false;
      }
      el.addEventListener('click',event =>{
        chart.setVisibility(pos,!vis);
        el.setAttribute('visible',String(!vis));
        });
      });
  }
  , 0);
}

public drawPoints(g, series, ctx, cx, cy, colour, radius): void {
    var annSeries = [];
    if (Array.isArray(this)){
        annSeries = this;
    }
    if (!annSeries.includes(series)){
        Dygraph.Circles.DEFAULT(g,series,ctx,cx,cy,colour,radius);
    }
    //console.log(g);
}

public doubleClickZoomOutPlugin = {
  activate: function(g) {
    // Save the initial y-axis range for later.
    const initialValueRange = g.getOption('valueRange');
    return {
      dblclick: e => {
        setTimeout(() => { // execute after default behaviour to preserve callbacks
          e.dygraph.updateOptions({
            dateWindow: null,  // zoom all the way out
            valueRange: initialValueRange  // zoom to a specific y-axis range.
          });
        }, 0);
      }
    }
  }
}

public darkenColor(colorStr: string): string {
  // Defined in dygraph-utils.js
  const color = Dygraph.toRGB_(colorStr);
  color.r = Math.floor((255 + color.r) / 2);
  color.g = Math.floor((255 + color.g) / 2);
  color.b = Math.floor((255 + color.b) / 2);
  return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
}

public barChartPlotter(e: any): any {
  const ctx = e.drawingContext;
  const points = e.points;
  const y_bottom = e.dygraph.toDomYCoord(-99);

  ctx.fillStyle = this.darkenColor(e.color);

  // Find the minimum separation between x-values.
  // This determines the bar width.
  let min_sep = Infinity;
  for (let i = 1; i < points.length; i++) {
      const sep = points[i].canvasx - points[i - 1].canvasx;
      if (sep < min_sep) { min_sep = sep; }
  }
  const bar_width = Math.floor(2.0 / 3 * min_sep);

  // Do the actual plotting.
  for (let i = 0; i < points.length; i++) {
      const p = points[i];
      const center_x = p.canvasx;

      ctx.fillRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);

      ctx.strokeRect(center_x - bar_width / 2, p.canvasy,
        bar_width, y_bottom - p.canvasy);
  }
}

public updateAnnotations(min?,max?,range?) {
    let comp, anno, dex, chart;
    if (Array.isArray(this)){
        [comp,anno,dex] = this;
        chart = comp.chartsRef[dex];
    } else {
        [comp,anno,dex,chart] = range;
    }
    chart.setAnnotations([]);
    let mindt;
    if (min){
        mindt = new Date(min);
    } else {
        mindt = new Date(chart.xAxisExtremes()[0]); 
    }
    anno.forEach(a => {
	if (a.xval && a.xval !== ''){
	    mindt = a.xval;
	}
        comp.drawAnnotation(mindt,chart,a);
    });

}
//public drawAnnotation(dt: Date,g: any,series: string,text: string,shorttext: string,class: string) {
public drawAnnotation(dt: Date|string,g: any,anno: annotations) {
    if (!anno.displayText){
      anno.displayText = '';
    }
    let annotations = g.annotations();
    let firstDate = 0;
    if (dt instanceof Date){
    let mins = dt.getMinutes();
    let hours = dt.getHours();
    if (mins % 15 !== 0){
        mins += 15 - (mins % 15); //round to neared 15 min interval
        if (mins == 60){
            mins = 0;
            hours += 1;
        }
    }
    let startstr: string = dt.getFullYear() + '-' + ('0' + (dt.getMonth()+1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2) + ' ' + ('0' + hours).slice(-2)+ ':' + ('0' + mins).slice(-2) + ':00'; 
    firstDate = new Date(startstr).getTime();
    } else {
        firstDate = parseFloat(dt);
    }
    annotations.push({
            series: anno.series,
            xval: firstDate,
            shortText: anno.displayText,
            text: anno.hoverText,
            cssClass: anno.class ? "annotation " + anno.class : "annotation"
        });
    //DEBUG console.log(annotations,g);
    g.setAnnotations(annotations);
}
public toggleExpansion(event, chart): void {
  const container = event.target.closest(".chart-container");
  if (!container) return;

  //collapse
  if (container.classList.contains("expanded")) {
    const overlay: HTMLElement = document.querySelector('.overlay');
    chart.expanded = false;
    overlay.classList.remove('overlay-open');
    overlay.style.zIndex = '';
    container.className = chart.classes;
    setTimeout(() => {
      chart.dygraph.resize();
      this.updateDOMWidth(chart.dygraph.graphDiv.offsetWidth);
    }, 0);

  //expand
  } else {
    const overlay: HTMLElement = document.querySelector('.overlay');
    overlay.classList.add('overlay-open');
    overlay.style.zIndex = '2000';
    chart.classes = container.classList.value;
    chart.expanded = true;
    const position = container.getBoundingClientRect();
    container.style.position = 'fixed';
    container.style.top = position.top + 'px';
    container.style.left = position.left + 'px';
    container.style.bottom = position.bottom + 'px';
    container.style.right = position.right + 'px';
    setTimeout(() => {
      container.className = "chart-container expanded";
      container.style = '';
      setTimeout(() => {
        chart.dygraph.resize();
        this.updateDOMWidth(chart.dygraph.graphDiv.offsetWidth);
      }, 400);
    }, 0);
  }
  };
public updateDOMWidth(width){
    const breakpoints = {
        sm: 200,
        md: 300,
        lg: 400,
        xl: 600
    };
    //console.log(width);
    let ret = Object.keys(breakpoints)[0];
    for (let v in breakpoints) {
        if (width < breakpoints[v]){
          this.domBreak = ret;
          return;
        }
        ret = v;
    };
    this.domBreak = ret;
}
private listenTabs(){
    const tabs = document.querySelectorAll('button.nav-link');
    tabs.forEach(el =>{
        this.renderer.listen(el,'click', event => this.redrawPlots(event)).bind(this);
    });
}

public getPopupContent(chart): string {
    //const datum_str = AHD ? 'the gauge datum' : 'AHD';
    let popup = '';
    if (chart.datums && chart.datums.length > 0){
        const datum_str = chart.datums.length > 1 || chart.datums[0] !== 'AHD' ? 'gauge datum' : 'AHD';
        popup += '<ul><li>' + chart.datums.join('</li><li>') +'</li></ul></p><p>To switch to ' + datum_str + ' please use the toggle below:</p>';
    }
    return popup;
}
public redrawPlots(e?){
    Object.values(this.chartsRef).forEach((c: Dygraph) =>{
        setTimeout(() => {
            c.resize();
        }, 300);
    });
}

}
