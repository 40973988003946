import { Component, ElementRef, OnInit } from '@angular/core';

import { HelperService } from '../../services/helper.service';
import { SharedService } from '../../services/shared.service';


@Component({
  selector: 'mhl-station-type-selector',
  templateUrl: './station-type-selector.component.html',
  styleUrls: ['./station-type-selector.component.sass']
})
export class StationTypeSelectorComponent implements OnInit {

  private domEl: HTMLElement;

  public defaultSelections: string | string[];
  public iconPath: string;
  public theme: string;
  public iconExtension: string;
  public orientation: string;

  public charmap: any;

  public stationTypes: string[];
  public selectedTypes: string[];
  private changes: MutationObserver;
  public loading: boolean;

  constructor(
    private elRef: ElementRef,
    private _helperService: HelperService,
    private _sharedService: SharedService
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
    
    const iconPath = this.domEl.getAttribute('iconPath');
    iconPath && iconPath !== '' ? this.iconPath = iconPath : this.iconPath = '';

    const theme = this.domEl.getAttribute('theme');
    theme && theme !== '' ? this.theme = theme : this.theme = ''; // TODO: implement default interface

    const iconExtension = this.domEl.getAttribute('iconExtension');
    iconExtension && iconExtension !== '' ? this.iconExtension = iconExtension : this.iconExtension = 'png';
    
    const orientation = this.domEl.getAttribute('orientation');
    orientation && orientation !== '' ? this.orientation = orientation : this.orientation = 'horizontal';

    this.charmap = {
        baro: 'Barometric',
        level: 'Water Level',
        oceantide: 'Ocean Tide',
        rain: 'Rainfall',
        waterquality: 'Water Quality',
        wave: 'Waverider'
        }
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {      
        mutations.forEach( (c: MutationRecord) => {                 
            if (c.attributeName == 'defaultvalue') {
                const characteristic = this.domEl.getAttribute('defaultValue');
                //DEBUG console.log(characteristic);
                if (characteristic && characteristic !== ''){
                    this._sharedService.filterSitesByType(characteristic,undefined,true);
                }
            }
        });
      });
    const defaultType = this.domEl.getAttribute('defaultType');
    if (defaultType && defaultType !== '') {
      this.addDefaultSelections(defaultType);
    }
      
    }

  ngOnInit(): void {
    this.stationTypes = Object.keys(this.charmap);
    this._sharedService.sitesLoading$.subscribe(status => this.loading = status);
    this._sharedService.selectedStationTypes$.subscribe(types => {
      this.selectedTypes = types;
      // console.log('this.selectedTypes', this.selectedTypes);
      this._sharedService.filterSitesByType(types,undefined,true);
    });
  }

  public toggleStationType(stationType: string): void {
    this._sharedService.updateSelectedStationTypes(stationType);
  }

  public toggleAllStationTypes(): void {
    const toggleTypes = this.stationTypes.filter(el => this.selectedTypes.indexOf(el) === -1);
    this._sharedService.updateSelectedStationTypes(toggleTypes);
  }

  private addDefaultSelections(defaultType): void {
    const attr = 'defaultType';
    const errorMessage = `You set the '${attr}' attribute value to '${defaultType}' but did not provide value for 'defaultValue' attribute.\n\nNo station types will be preselected`;
    
    const defaultValue = this.domEl.getAttribute('defaultValue');
    if (defaultValue && defaultValue !== '') {
      switch (defaultType) {
        case 'types':
          if (defaultValue == 'all'){
            this.stationTypes = Object.keys(this.charmap);
            this.selectedTypes = [];
            this.toggleAllStationTypes();
          } else {
          this.changes.observe(this.domEl, {attributes: true}); 
          const parsedDefaultTypes = this._helperService.parseAttribute('json', defaultValue, [], 'defaultValue') as JSON;
          this._sharedService.updateSelectedStationTypes(parsedDefaultTypes);
          }
          break;
        default:
          console.error(`Provided value for '${attr}' attribute is not supported, please use one of the following: 'url', 'types'\n\nNo station types will be preselected`);
      }
    } else {
      console.error(errorMessage);
    }

  }

}
