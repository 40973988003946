import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewEncapsulation, NgZone, HostListener } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Site } from 'achelous/dist/data/models/Site';
import { SharedService } from '../../services/shared.service';
import { HelperService } from '../../services/helper.service';
import { GeoJson } from '../../interfaces/index';
import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-fullscreen";
import "leaflet-rotatedmarker";
import "leaflet.vectorgrid";

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

const ARROW_SETTINGS = {
  arrowUrl: '<svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20"><path fill="{arrowColour}" d="M 11.871,0 7,6h3v16h4V6h3z"/></svg>',
  arrowColour: '#000000',
};

@Component({
  selector: 'mhl-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class MapComponent implements OnInit {

  private domEl: HTMLElement;
  public objectKeys = Object.keys;

  public favouritable: boolean;
  public selectable: boolean;
  public singleSite: boolean=false;
  public noPan: boolean=false;
  public legend: L.Control;
  public legendSrc: string;
  public height: any; // TODO: interface
  public theme: string;
  public markerType: string;
  public markerExtension: string;
  public markerPath: string;
  public mapView: boolean;
  public listView: boolean;
  public dateFormat: string;
  public defaultType: any;
  public defaultValue: any;
  public ifdLink: boolean = false;
  public linkToFavs: boolean = false;
  public jsonType: string = 'hazard';
  private geoJSON$: Subject<any> = new Subject<any>();
  private changes: MutationObserver;
  public fullscreen: boolean = true;

  public selectedSites: Site[] = [];
  public selectedSitesForControls: Site[];
  public favouriteSites: Site[] = [];
  public defaultSelections: Site[];
  public loading: boolean;
  public progress: number;

  public map: L.Map & {isFullscreen?(): boolean}; //ANDs together Map and new object to allow compiling
  public zoom: number;
  public bounds: L.LatLngBounds
  public mapCenter: L.LatLng;
  public baseMaps: any;
  public siteGroup: L.FeatureGroup;
  public favouriteGroup: L.FeatureGroup;
  public allLayers: any;
  public mapOptions: any;//L.MapOptions;
  public layerControls: any;
  public controlOptions: any = {sortLayers: true};
  public sitebar: any;
  public clickedSite: Site;
  public focus: boolean = false;
  public small: boolean = false; //screen width for icon toggling
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
      if (window.innerWidth < 992){
          this.small = true;
      } else {
          this.small = false;
      }
}
  constructor(
    private elRef: ElementRef,
    private _sharedService: SharedService,
    public _helperService: HelperService,
    private zone: NgZone,
    private httpClient: HttpClient
  ) {
    this.domEl = elRef.nativeElement as HTMLElement;
    this._sharedService.isMap = true;

    let favoritable: string | boolean = this.domEl.getAttribute('favouritable');
    favoritable && favoritable !== '' ? favoritable = JSON.parse(favoritable) : favoritable = true; // TODO: implement default interface
    typeof favoritable === 'boolean' ? this.favouritable = favoritable : this.favouritable = true;

    let selectable: string | boolean = this.domEl.getAttribute('selectable');
    selectable && selectable !== '' ? selectable = JSON.parse(selectable) : selectable = true;
    typeof selectable === 'boolean' ? this.selectable = selectable : this.selectable = true;

    let singleSite: string | boolean = this.domEl.getAttribute('singleSite');
    if (singleSite && JSON.parse(singleSite)) this.singleSite = true;

    let noPan: string | boolean = this.domEl.getAttribute('noPan');
    if (noPan && JSON.parse(noPan)) this.noPan = true;

    let height: any = this.domEl.getAttribute('height'); // TODO: interface
    height && height !== '' ? height = JSON.parse(height) : height = { measure: 'vh', value: 40 };
    typeof height.measure === 'string' && typeof height.value === 'number' ? this.height = height : this.height = { measure: 'vh', value: 40 };

    const css = `ngb-tabset .tab-content .tab-pane {max-height: ${this.height.value}${this.height.measure} !important;}`;
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    const theme = this.domEl.getAttribute('theme');
    theme && theme !== '' ? this.theme = theme : this.theme = ''; // TODO: implement default interface

    const markerType = this.domEl.getAttribute('markerType');
    markerType && markerType !== '' ? this.markerType = markerType : this.markerType = 'file';

    const markerExtension = this.domEl.getAttribute('markerExtension');
    markerExtension && markerExtension !== '' ? this.markerExtension = markerExtension : this.markerExtension = 'png';

    const dateFormat = this.domEl.getAttribute('dateFormat');
    dateFormat && dateFormat !== '' ? this.dateFormat = dateFormat : this.dateFormat = 'hh:mm a dd-MMM';

    const markerPath = this.domEl.getAttribute('markerPath');
    markerPath && markerPath !== '' ? this.markerPath = markerPath : this.markerPath = ''; // TODO: implement default interface

    const mapView = this.domEl.getAttribute('mapView');
    mapView && mapView !== '' ? this.mapView = _helperService.parseAttribute('boolean', mapView, true, 'mapView') as boolean : this.mapView = true;

    const listView = this.domEl.getAttribute('listView');
    listView && listView !== '' ? this.listView = _helperService.parseAttribute('boolean', listView, true, 'listView') as boolean : this.listView = true;

    const ifdLink = this.domEl.getAttribute('ifdLink');
    if (ifdLink && ifdLink !== '') this.ifdLink = _helperService.parseAttribute('boolean', ifdLink, true, 'ifdLink') as boolean;

    const jsonType = this.domEl.getAttribute('jsonType');
    if (jsonType && jsonType !== '') this.jsonType = jsonType;

    const linkToFavs = this.domEl.getAttribute('favouritesLink');
    if (linkToFavs && linkToFavs !== '') this.linkToFavs = _helperService.parseAttribute('boolean', linkToFavs, true, 'linkToFavs') as boolean;

    const fullscreen = this.domEl.getAttribute('fullscreen');
    if (fullscreen && fullscreen !== '') this.fullscreen = _helperService.parseAttribute('boolean', fullscreen, true, 'fullscreen') as boolean;

    const legendSrc = this.domEl.getAttribute('legend');
    if (legendSrc && legendSrc !== '') this.legendSrc = legendSrc;

    this.sitebar = {opened: false,closeOutside: true,loading: false};
    const defaultType = this.domEl.getAttribute('defaultType');

    //construct attribute listener for externally updating rendered geojson
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach( (c: MutationRecord) => {
            if (c.attributeName.toLowerCase() == 'defaultvalue') {
                let geojson = this.domEl.getAttribute('defaultValue');
                if (geojson && geojson !== ''){
                    try {
                        geojson = JSON.parse(geojson);
                    } catch(e) {
                        if (!geojson.toLowerCase().includes('storage')){
                            console.log("Geojson attribute parsed as string",geojson);
                        }
                    }
                    this.geoJSON$.next(geojson);
                }
            }
        });
      });
    this.geoJSON$.subscribe(f => { 
    let gets = {};
    if (typeof f === 'string'){
        gets = {default: f};
      } else {
          gets = f
      }
      //DEBUG console.log('gets', gets)
    for (const loc in gets){
        let json;
        if (gets[loc].toLowerCase().includes('sessionstorage')){
            const key = gets[loc].split('.').slice(-1)[0];
            json = JSON.parse(sessionStorage.getItem(key));
        }
        if (gets[loc].toLowerCase().includes('localstorage')){
            const key = gets[loc].split('.').slice(-1)[0];
            json = JSON.parse(localStorage.getItem(key));
        }
        if (json){
            if (this.jsonType == 'marker' || ('jsonType' in json && json.jsonType == 'marker')){
                this.loadMarkerGeoJSON(json,loc);
            } else {
                this.loadHazardGeoJSON(json,loc);
            }
        } else {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', gets[loc]);
        //xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.responseType = 'json';
        xhr.onload = function() {
            if (xhr.status !== 200) {
            console.warn('geoJSON not correctly loaded. Return status: ', xhr.status);
            return;
            }
            json = xhr.response;
            if (!(typeof json == 'object')){
                json = JSON.parse(json);
            }
            if (this.jsonType == 'marker'){;
                this.loadMarkerGeoJSON(json,loc);
            } else {
                this.loadHazardGeoJSON(json,loc);
            }
        }.bind(this);
        xhr.send();
        }
      }
    });
    if (defaultType && defaultType !== '') {
      this.defaultType = defaultType;
      this.addDefaultSelections();
    }
 }

  ngOnInit(): void {
    if (window.innerWidth < 992){
        this.small = true;
    }
    this.mapCenter = L.latLng(-33.8688,151.2093);
    this.zoom = 12;
    this.siteGroup = L.featureGroup([]);
    this.baseMaps = {
        'Streets': esri.basemapLayer('Streets'),
        'Satellite': L.layerGroup([
            esri.basemapLayer('Imagery'),
            esri.basemapLayer('ImageryLabels')
        ]),
        'Imagery': esri.tiledMapLayer({url: "https://maps.six.nsw.gov.au/arcgis/rest/services/public/NSW_Imagery/MapServer"})
    };
    this.layerControls = {
        baseLayers: this.baseMaps,
        overlays: {}
      };
    this.allLayers = [this.baseMaps["Streets"]];
    this.mapOptions= {
        zoom: this.zoom,
        maxZoom: 16,
        zoomSnap: 0.25,
        center: this.mapCenter,
        scrollWheelZoom: false,
        gestureHandling: true,
        
    };
    if (this.defaultType !== 'geojson' && this.defaultType !== 'vectorSlice' && this.defaultType !== 'tileLayer'){
      this._sharedService.selectedSites$.subscribe(selectedSites => {
        const sitecodes = selectedSites.map(s => s.sitecode);
        const selectedCodes = this.selectedSites.map(s => s.sitecode);
        if ((selectedSites.length > 0 || this.selectedSites.length >0) && !this._helperService.isEqual(sitecodes,selectedCodes)) {
          //console.log('selectedSites', selectedSites);
          this.selectedSites = selectedSites;
          const deldex = this.allLayers.findIndex(s => s === this.siteGroup);
          if (deldex >= 0){
              this.allLayers.splice(deldex,1);
          }
          this.siteGroup = L.featureGroup([]);
          for (const site of this.selectedSites) {
              if (site && !this.favouriteSites.includes(site)){
              //console.log(site);
                const marker = L.marker([ site.latitude,site.longitude ], {
                icon: L.icon({
                    iconSize: [ 28, 28 ],
                    iconAnchor: [ 14, 28 ],
                    iconUrl: this.getMarkerIcon(site.characteristic,this.markerExtension)
                })
                })
                .bindTooltip('<b>' + site.name + '</b> (' + site.sitecode + ')',{direction: 'right', offset:[12,-15]});
                marker.on('click', e => {
                this.zone.run( async () => {
                this.clickedSite = site;
                this.sitebar.loading = true;
                this.sitebar.opened = true;
                if (!this.clickedSite.latest_values || Object.keys(this.clickedSite.latest_values).length == 0){
                    this.clickedSite.latest_values = await this._sharedService.getLatestReadingsFromSite(site);
                }
                this.sitebar.loading = false;
                });
                });
            marker.addTo(this.siteGroup);
            }
          }
          this.allLayers.push(this.siteGroup);
          this.layerControls.overlays["Sites"] = this.siteGroup;
          if (this.selectedSites.length > 0 && this.map){
            this.bounds = this.siteGroup.getBounds();
            if (this.favouriteSites.length > 0){
                this.bounds.extend(this.favouriteGroup.getBounds());
            }
            if (!this.noPan){
                this.map.fitBounds(this.bounds);
            }
          }
        }
    });
    this.getFavourites();
    this._sharedService.selectedSitesForControls$.subscribe(sites => {
        this.selectedSitesForControls = sites;
    });
  } else if (this.defaultType == 'tileLayer'){
    this.addDefaultSelections();
  } else {
    this.mapOptions.maxZoom = 19;
  }
  }
  public addToControls(site: Site, event?: MouseEvent): void {
    if (event) {
      //event.preventDefault();
      event.stopPropagation();
    }
    if (this.singleSite && site !== this.selectedSitesForControls[0]){
        this._sharedService.updateSelectedSitesForControls(site,true);
    } else {
        this._sharedService.updateSelectedSitesForControls(site);
    }
  }
  public isInControls(sitecode: string): boolean {
    let controlSitecodes = this.selectedSitesForControls.map(s => s.sitecode);
    return controlSitecodes.includes(sitecode) ? true : false;
  }
  public isFavourited(sitecode: string): boolean {
    return this._sharedService.isfavourited(sitecode);
  }
  public addToFavourites(sitecode: string, event?: MouseEvent): void {
    if (this.favouritable) {
      if (event) {
        //event.preventDefault();
        event.stopPropagation();
      }

      this._sharedService.addSitecodeToFavourites(sitecode); //this will add or remove
      //this.getFavourites(); subscribe should still be active
    }
  }

  public getFavourites(): void {
    if (this.favouritable){
    this._sharedService.favourites$.subscribe(favourites => {
        this.favouriteSites = favourites.sites;
        const deldex = this.allLayers.findIndex(s => s === this.favouriteGroup);
        if (deldex >= 0){
            this.allLayers.splice(deldex,1);
        }
        this.favouriteGroup = L.featureGroup([]);
        if (this.favouriteSites.length > 0) {
            for (const site of this.favouriteSites) {
                const marker = L.marker([ site.latitude,site.longitude ], {
                  icon: L.icon({
                    iconSize: [ 28, 28 ],
                    iconAnchor: [ 14, 28 ],
                    iconUrl: this.getMarkerIcon(site.characteristic,this.markerExtension)
                  })
                })
                .bindTooltip('<b>' + site.name + '</b> (' + site.sitecode + ')',{direction: 'right', offset:[12,-15]});
                marker.on('click', e => {
                  this.zone.run(async () => {
                  this.clickedSite = site;
                  //this.sitebar.loading = true;
                  this.sitebar.opened = true;
                  this.clickedSite.latest_values = await this._sharedService.getLatestReadingsFromSite(site);
                  //this.sitebar.loading = false;
                  });
                });
                marker.addTo(this.favouriteGroup);
              }
              this.bounds = this.favouriteGroup.getBounds();
              if (this.selectedSites.length > 0 && this.map){
                this.bounds.extend(this.siteGroup.getBounds());
              }
              if (!this.noPan){
                this.map.fitBounds(this.bounds);
              }
        }
        this.allLayers.push(this.favouriteGroup);
        this.layerControls.overlays["Favourites"] = this.favouriteGroup;
      });
    };
  }

  public async onMapReady(map: L.Map): Promise<void> {    
    setTimeout(() => {
        this.map = map;
        this.map.invalidateSize(); //NEED THIS FOR MAP TO WORK!!!
        L.control.scale({imperial:false, maxWidth: 200}).addTo(this.map);
        if (this.fullscreen){
            L.control.fullscreen({pseudoFullscreen: true}).addTo(this.map);
        }
        if (this.selectedSites.length > 0){
            this.bounds = this.siteGroup.getBounds();
        }
        if (this.favouriteSites.length > 0){
            if (!this.bounds.isValid()){
                this.bounds = this.favouriteGroup.getBounds();
            } else {
                this.bounds.extend(this.favouriteGroup.getBounds());
            }
        }
        if (this.bounds && this.bounds.isValid() && !this.noPan){
            this.map.fitBounds(this.bounds);
        }
        if (this.noPan){ //pan to default map view - i.e. Sydney
            this.map.setView(new L.LatLng(-33.8439, 151.2166), 10.3);
        }
        if (this.legendSrc){ 
          this.legend = new L.Control({
            position: 'bottomright',
          });
          this.legend.onAdd = (map) => { 
            let div = L.DomUtil.create('div', 'map-legend');
            div.innerHTML += '<img src="' + this.legendSrc + '" alt="legend">';
            return div
          }
          this.legend.addTo(this.map);
        }
    }, 0);
  }
  public toggleSidebar() {
    this.sitebar.opened = !this.sitebar.opened;
  }
  private async addDefaultSelections(): Promise<void> {
    const attr = 'defaultType';
    const errorMessage = `You set the '${attr}' attribute value to '${this.defaultType}' but did not provide value for 'defaultValue' attribute.\n\nNo sites will be preselected`;
    this.defaultValue = this.domEl.getAttribute('defaultValue');
    if (this.defaultValue && this.defaultValue !== '') {
      switch (this.defaultType) {
        case 'sitecodes':
          let parsedDefaultCodes: string | string[];
          if ((/\[(".+")+\]/).test(this.defaultValue)) {
            parsedDefaultCodes = this._helperService.parseAttribute('json', this.defaultValue, [], 'defaultValue') as string[];
          } else {
            if (Array.isArray(this.defaultValue.split(','))) {
              parsedDefaultCodes = this.defaultValue.split(',') as string[];
              const validFormat = parsedDefaultCodes.some(code => (/^[a-z0-9]+$/i).test(code));
              validFormat ? parsedDefaultCodes = parsedDefaultCodes : parsedDefaultCodes = [];
            }
          }
          if (Array.isArray(parsedDefaultCodes) && parsedDefaultCodes.length > 0) {
            this._sharedService.allSites$.pipe(takeWhile(ss => !(ss && ss.length > 0), true)).subscribe(async allSites =>{
              if (allSites && allSites.length > 0){
                this.defaultSelections = [];
                let notFound = [];
                (parsedDefaultCodes as string[]).forEach(s => {
                  const found = allSites.find(x => x.sitecode == s);
                  if (found){
                    this.defaultSelections.push(found)
                  } else {
                    notFound.push(s);
                  }
                });
                if (notFound.length > 0){
                  const fsites = await this._sharedService.getSitesFromSitecodes(parsedDefaultCodes);
                  this.defaultSelections = [...this.defaultSelections,...fsites];
                }
                this._sharedService.updateSelectedSites(this.defaultSelections);
              }
            });
          } else {
            console.error(`The 'defaultValue' either has incorrect format or empty\n\nNo sites will be preselected`);
          }
          break;
        case 'collections':
          let parsedDefaultCollections: string | string[];
          if ((/\[(".+")+\]/).test(this.defaultValue)) {
            parsedDefaultCollections = this._helperService.parseAttribute('json', this.defaultValue, [], 'defaultValue') as string[];
          } else {
            if (Array.isArray(this.defaultValue.split(','))) {
              parsedDefaultCollections = this.defaultValue.split(',') as string[];
              const validFormat = parsedDefaultCollections.some(code => (/^[0-9]+$/i).test(code));
              validFormat ? parsedDefaultCollections = parsedDefaultCollections : parsedDefaultCollections = [];
            }
          }
          if (Array.isArray(parsedDefaultCollections) && parsedDefaultCollections.length > 0) {
            combineLatest(this._sharedService.collectionsList$,this._sharedService.allSites$)
                .pipe(takeWhile(([c,s]) => !(c && c.length > 0) || !(s && s.length  > 0), true)).subscribe(async ([collections,sites]) => {
                    if (collections && sites && collections.length > 0 && sites.length >0){
                        const colIds = collections.map(c => c.id);
                        if ((parsedDefaultCollections as string[]).every(c => colIds.includes(c))){
                            const collectionSites = await this._sharedService.filterSitesByCollection(parsedDefaultCollections,sites,true);
                            this.defaultSelections = collectionSites;
                        } else {
                            await this._sharedService.addNewCollection(parsedDefaultCollections as string[],true);
                        }
                    }

                });
          } else {
            console.error(`The 'defaultValue' either has incorrect format or empty\n\nNo sites will be preselected`);
          }
          break;
        case 'characteristic':
          let parsedDefaultTypes: string | string[];
          if ((/\[(".+")+\]/).test(this.defaultValue)) {
            parsedDefaultTypes = this._helperService.parseAttribute('json', this.defaultValue, [], 'defaultValue') as string[];
          } else {
            if (Array.isArray(this.defaultValue.split(','))) {
              parsedDefaultTypes = this.defaultValue.split(',') as string[];
              const validFormat = parsedDefaultTypes.some(code => (/^[a-z0-9]+$/i).test(code));
              validFormat ? parsedDefaultTypes = parsedDefaultTypes : parsedDefaultTypes = [];
            }
          }
          if (Array.isArray(parsedDefaultTypes) && parsedDefaultTypes.length > 0) {
            // const sites = await this._sharedService.getSitesFromTypes(parsedDefaultTypes);
            this._sharedService.allSites$.pipe(takeWhile(ss => !ss && ss.length == 0, true)).subscribe(allSites =>{
              if (allSites && allSites.length >0){
                this._sharedService.filterSitesByType(parsedDefaultCollections,allSites);
              }
            });
          } else {
            console.error(`The 'defaultValue' either has incorrect format or empty\n\nNo sites will be preselected`);
          }
          break;
        case 'geojson':
          if (this.defaultValue && this.defaultValue !== 'wait'){ //used 'wait' keyword to allow instantiation before first layer loaded
                let geojson = {};
                try {
                     geojson = JSON.parse(this.defaultValue);
                } catch(e) {
                    console.log("Geojson attribute parsed as string",geojson);
                    geojson = this.defaultValue;
                }
              this.geoJSON$.next(geojson);
          }
          //begin observing DOM for new geojson data
          this.changes.observe(this.elRef.nativeElement, {attributes: true});
          break;
        case 'vectorSlice':
	  this.controlOptions['collapsed'] = false;
          /*if (!this.allLayers){
            //init not yet run - will be loaded via init later on
            return;
          }
	 */
          if(this.defaultValue){
            const sliceLayers = JSON.parse(this.defaultValue);
            //console.log(sliceLayers);
            
            sliceLayers.forEach(layer => {
              const uri = layer['uri'];
              const name = layer['name'];
              //console.log('uri & name',uri, name);
              const style = layer['style'];
              const clickProps = layer['clickProps'];
              this.loadVectorGrid(uri, name, style, clickProps);
            });
          }
          break;
        case 'tileLayer':
	  this.controlOptions['collapsed'] = false;
          if (!this.allLayers){
            //init not yet run - will be loaded via init later on
            return;
          }
          if(this.defaultValue){
            //do fetch all features here
            const tileLayer = JSON.parse(this.defaultValue);
            Object.keys(tileLayer).forEach(name => {
              //DEBUG console.log(name, tileLayer[name]);
              const uri = tileLayer[name];
              this.loadtileLayer(uri, name);
            });

          }
          break;

        default:
          console.error(`Provided value for '${attr}' attribute is not supported, please use one of the following: 'sitecodes', 'collections', 'characteristic', 'geojson'\n\nNo sites will be preselected`);
      }
    } else {
      console.error(errorMessage);
    }

  }
  public loadVectorGrid(uri, name, style, clickProps): void {
    if (!style){
    	style = {};
    }
    const vectorGridOption = {
      maxZoom: 18,
      rendererFactory: (L.svg as any).tile,
			vectorTileLayerStyles: {
				sliced: function(properties, zoom) {
					return style
				}
			},
			interactive: true,
		}

    let showLocationName = false
    this.httpClient.get(uri).subscribe(result =>{
      //console.log('result:',result)
      const vectorGrid = L.vectorGrid.slicer(result, vectorGridOption).on(
        'click', (e) => {
          const properties = e.layer.properties;
          let content = `<h3>${name}</h3>`;
	  clickProps.forEach(element => {
       	      if (element in properties){
	          content += `<p>${properties[element]}</p>`;
	      }
          });
          L.popup().setLatLng(e.latlng).setContent(content).openOn(this.map);
         });
      this.allLayers.push(vectorGrid);
      this.layerControls.overlays[name] = vectorGrid;
    });
  }


  public loadtileLayer(uri: string,name: string): void {
    const tileLayerOpt = {
	    attribution: '',
	    errorTileUrl: 'https://s3-ap-southeast-2.amazonaws.com/www-data.manly.hydraulics.works/projects/OEH/NSW_Inundation/no_data.png',
	    minZoom: 0,
	    maxZoom: 18,
	    minNativeZoom: 7,
	    maxNativeZoom: 17,
	    bounds:  L.latLngBounds(L.latLng(-28.1648,149.8418),L.latLng(-37.4718,153.6218))
    };
    const layer = L.tileLayer(uri, tileLayerOpt);
    function handleTileError(evt){
      if (evt.tile._hasError) return;
        evt.tile._hasError = true;
	      evt.tile.src = L.Util.emptyImageUrl;
    }
    layer.on('tileerror', handleTileError);
    this.layerControls.overlays[name] = layer;
    this.allLayers.push(layer);
  }

  public getMarkerIcon(characteristics: string | string[], svgxmlstring: string): string | null { // Order is important because there can be multiple types in a string, but have different priority
    let path: string | null;
    if (Array.isArray(characteristics)){
      characteristics = characteristics.join(',');
    }
    switch (this.markerType) {
      case 'string':
        path = 'data:image/svg+xml;base64,' + window.btoa(svgxmlstring);
        break;
      default:
        if (this.markerPath !== '') {
          path = `FILENAME.${this.markerExtension}`;
          let filename: string = 'undefined';
          if (RegExp(/level/).test(characteristics)) {
            filename = 'level';
          }
          if (RegExp(/rain/).test(characteristics)) {
            if (filename == 'level'){
              filename = 'level+rain';
            } else {
              filename = 'rain';
            }
          }
          if (RegExp(/baro/).test(characteristics)) {
            filename = 'baro';
          }
          if (RegExp(/oceantide/).test(characteristics)) {
            filename = 'oceantide';
          }
          if (RegExp(/wave/).test(characteristics)) {
            filename = 'wave';
          }
          if (RegExp(/waterquality/).test(characteristics) || RegExp(/water-quality/).test(characteristics)) {
            filename = 'waterquality';
          }
          if (RegExp(/pump/).test(characteristics)) {
            filename = 'pump';
          }
          if (RegExp(/slope/).test(characteristics)) {
            filename = 'slope';
          }
          if (filename === 'undefined'){
            filename = characteristics;
          }
          // filename = characteristics.replace(',', '.');
          path = this.markerPath + path.replace(/FILENAME/, filename);
        } else {
          path = this.markerPath + path.replace(/FILENAME/, 'undefined');
        }
    }
    return path;
  }
  public loadHazardGeoJSON(geojson: GeoJson,loc: string): void {
    let hazardPoints = L.featureGroup([]);
    let hazardPolys = L.featureGroup([]);
    let inundationPolys = L.featureGroup([]);
    L.geoJSON(geojson, {
      style: (feat) => {
        if (feat.geometry.type == 'Point'){ //assumes hazard type point
          return {};
        } else if (feat.properties.hasOwnProperty('hazard')){ //assumes hazard type line/polygon
          const type = feat.properties['alert'];
          const options = {
            weight: 1,
            opacity: 1,
            fillOpacity: 0
          };
          if (type == 1) options['color'] = 'orange';
          else if (type == 2) options['color'] = 'red';
          else options['color'] = 'green';
          feat.properties['style'] = options;
          return options;
        } else { // assumes inundation polygon
          return feat.properties['style'];
        }
      },
      onEachFeature: (feature, layer) => {
        //DEBUG console.log(feature,layer);
          if (feature.geometry.type == 'Point') {
            const type = feature.properties['alert'];
            const options = {
              color: "#000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            };
            if (type == 1) options['fillColor'] = 'orange';
            else if (type == 2) options['fillColor'] = 'red';
            else options['fillColor'] = 'green';
            const marker = L.circleMarker((layer as L.Marker).getLatLng(),options); //layer will always be Marker object but need to tell compiler so getLatLng method is available
            if ('click' in feature.properties){
                const html = this.getPopupHTML(feature.properties['title'],feature.properties['click']);
                marker.bindPopup(html);
            } else if ('hover' in feature.properties){
                const html = this.getPopupHTML(feature.properties['title'],feature.properties['hover']);
                marker.bindTooltip(html,{direction: 'right', offset:[12,-15]});
            }
            hazardPoints.addLayer(marker);
          } else if (feature.properties.hasOwnProperty('hazard')){
            const html = this.getPopupHTML(feature.properties['title'],feature.properties['click']);
            layer.bindPopup(html);
            layer.on({
              mouseover: this.setFeatureStyle.bind({fillColor: 'grey', fillOpacity: 0.5}),
              mouseout: this.setFeatureStyle.bind(feature.properties['style'])
            });
            layer.addTo(hazardPolys);
          } else {
            const html = this.getPopupHTML(feature.properties['title'],feature.properties['click']);
            layer.bindPopup(html);
            layer.on({
              mouseover: this.setFeatureStyle.bind(feature.properties['hoverstyle']),
              mouseout: this.setFeatureStyle.bind(feature.properties['style'])
            });
            layer.addTo(inundationPolys);
          }
      }
    });
    //order of added layers controls render order
    if (inundationPolys.getLayers().length > 0){
    if ((loc + " Inundation") in this.layerControls.overlays || loc == 'default'){
        const oldGroup = loc !== 'default' ? this.layerControls.overlays[loc + " Inundation"] : this.layerControls.overlays["Inundation"];
        //DEBUG console.log("removing " + loc);
        const deldex = this.allLayers.findIndex(s => s === oldGroup);
        if (deldex >= 0){
            this.allLayers.splice(deldex,1);
        }
      }
      if (loc == 'default'){
        this.layerControls.overlays["Inundation"] = inundationPolys;
        } else {
        this.layerControls.overlays[loc + " Inundation"] = inundationPolys;
        }
      this.allLayers.push(inundationPolys);
    }
    if (hazardPolys.getLayers().length > 0){
      if ("Polygons" in this.layerControls.overlays){
        const oldGroup = this.layerControls.overlays["Polygons"]
        const deldex = this.allLayers.findIndex(s => s === oldGroup);
        if (deldex >= 0){
            this.allLayers.splice(deldex,1);
        }
      }
      this.layerControls.overlays["Polygons"] = hazardPolys;
      this.allLayers.push(hazardPolys);
    }
    if (hazardPoints.getLayers().length > 0){
      if ((loc + " Points") in this.layerControls.overlays || loc == 'default'){
        const oldGroup = loc !== 'default' ? this.layerControls.overlays[loc + " Points"] : this.layerControls.overlays["Points"];
        const deldex = this.allLayers.findIndex(s => s === oldGroup);
        if (deldex >= 0){
            this.allLayers.splice(deldex,1);
        }
      }
      if (loc == 'default'){
        this.layerControls.overlays["Points"] = hazardPoints;
        } else {
        this.layerControls.overlays[loc + " Points"] = hazardPoints;
        }
      this.allLayers.push(hazardPoints);
    }
    this.bounds = inundationPolys.getBounds();
    for (const layer in this.layerControls.overlays) {
       this.bounds.extend(this.layerControls.overlays[layer].getBounds());
    }
    //this.bounds = hazardPoints.getBounds().extend(hazardPolys.getBounds()).extend(inundationPolys.getBounds());
    if (this.bounds.isValid()){
      this.map.fitBounds(this.bounds);
    }
  }

  public loadMarkerGeoJSON(geojson: GeoJson,loc: string): void {
    const layer = L.geoJSON(geojson, {
        pointToLayer: (feature, latlng) => {
            const hasPopup = 'hover' in feature.properties;
            if (feature.properties["icon"] == 'ARROW'){ //set rotated arrow marker
              const iconScale = feature.properties["arrowScale"] ? feature.properties["arrowScale"] : 1;
              const iconHTML = L.Util.template(ARROW_SETTINGS.arrowUrl,
              feature.properties["arrowColour"] ? {arrowColour: feature.properties["arrowColour"]} : ARROW_SETTINGS
            ); // replace the fill colour with that provided in the geojson
            const divIcon = L.divIcon({
              className: "leaflet-arrow-marker",
              html: iconHTML,
              iconAnchor  : [12 * iconScale, 0],
              iconSize    : [24 * iconScale, 20 * iconScale]
            });
            const rotation = feature.properties["rotation"] ? feature.properties["rotation"] : 0;
            const marker = L.marker(latlng, {icon: divIcon, rotationAngle: rotation})
            if (hasPopup){
                const popupHTML = this.getPopupHTML(feature.properties["title"],feature.properties["hover"]);
                const yOff = Math.cos(rotation*Math.PI/180) * 12;
                marker.bindTooltip(popupHTML,{direction: 'right', offset:[12,yOff]});
            }
            return marker;
          } else if (feature.properties["icon"] == 'CIRCLE'){
            let options = {
              color: "#000",
              weight: 1,
              opacity: 1,
              fillOpacity: 0.8
            };
            if ("style" in feature.properties) {
                options = Object.assign(options,feature.properties["style"]);
            }
            const marker = L.circleMarker(latlng,options); //layer will always be Marker object but need to tell compiler so getLatLng method is available
            if (hasPopup){
                const popupHTML = this.getPopupHTML(feature.properties["title"],feature.properties["hover"]);
                marker.bindTooltip(popupHTML,{direction: 'right', offset:[12,-15]});
            }
            return marker;
          } else {
            const icon = L.icon({
                  iconSize: [28, 28],
                  iconAnchor: [14, 28],
                  iconUrl: feature.properties["icon"]
              });
            const marker = L.marker(latlng, {icon: icon, rotationAngle: feature.properties["rotation"] ? feature.properties["rotation"] : 0});
            if (hasPopup){
                const html = this.getPopupHTML(feature.properties["title"],feature.properties["hover"]);
                marker.bindTooltip(html,{direction: 'right', offset:[12,-15]});
            }
            return marker;
          }
        }
    });
    //layer.addTo(this.map);
    this.bounds = layer.getBounds();
    if (loc == 'default'){
        loc = 'Custom Layer';
	if ('layerName' in geojson){
	    loc = geojson['layerName']; 
	} 
    }
    if (loc in this.layerControls.overlays){
       const oldGroup = this.layerControls.overlays[loc];
       const deldex = this.allLayers.findIndex(s => s === oldGroup);
        if (deldex >= 0){
            this.allLayers.splice(deldex,1);
        }
    }
    this.layerControls.overlays[loc] = layer;
	this.allLayers.push(layer);
    if (this.bounds.isValid() && this.map){
      this.map.fitBounds(this.bounds);
    }
    }

public getPopupHTML(title,content): string {
    let popupStr = '<div align="left">';
    //DEBUG console.log(content);
    if (title){
        popupStr += '<h3>'+ title + '</h3>';
    }
    if (content){
      popupStr += '<table class="table table-sm table-borderless">';
      for (const key of Object.keys(content).sort()) {
        popupStr+= "<tr><td><b>" + key + "</b>:</td><td>" + content[key] + "</td></tr>";
      }
      popupStr += "</table></div>";
    }
    return popupStr;
  }
  public setFeatureStyle(event): void {
    //must be called with bind so that target styling is assigned to `this`
    const layer = event.target;
    layer.setStyle(this);
  }
  public sortByName(sites: Site[]){
    return sites.sort((a,b) =>{
        return (a.name > b.name) ? 1 : -1;
    });
  }
}
